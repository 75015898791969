.CouponCode__Section {
    padding: 10px;
    border: 1px solid #dedede;

    .CouponCode__title {
        background-color: #dedede;
        padding: 10px;
        text-align: center;
    }

    .CouponCode__body {
        margin: 15px 0 20px;
        position: relative;

        .CouponCode__input {
            width: 100%;
            height: 44px;
            border: 1px solid #d2d2d2;
            border-radius: 4px;
            padding: 10px 150px 10px 10px;
            color: #2b2b2b;
        }

        .CouponCode__btn {
            padding: 10px;
            border: none;
            outline: none;
            background-color: #000;
            border-radius: 4px;
            color: #FFF;
            font-weight: 600;
            letter-spacing: 1px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .CouponCode__error {
        color: red;
    }

    .couponCode__success {
        color: green;
    }

    .couponCode__error {
        color: red;
    }
}

@media only screen and (max-width: 474px) {
    .CouponCode__Section {
        .CouponCode__body {
            .CouponCode__input {
                padding: 10px;
            }

            .CouponCode__btn {
                position: inherit;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}