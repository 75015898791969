.oh-my-pop-brand-content {
    text-align: center;
    max-width: 1200px;
    margin: auto;

    .Brand-desc {
        margin-bottom: 16px;
    }

    .Brand-title {
        font-weight: bold;
        text-transform: uppercase;
    }
}

.BrandBlockFiveBanner {

    max-width: 1830px;
    margin: auto;

    .BrandImgContent {
        position: absolute;
        bottom: 20px;
        left: 30px;
        padding: 15px;

        .Gallery__title {
            letter-spacing: 0px;
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            background-color: #0da5e8;
            padding: 5px 15px;
            // text-align: center;
        }

        .img-desc {
            letter-spacing: 0px;
            color: #FFFFFF;
            font-weight: 400;
            font-size: 18px;
            line-height: 31px;
            padding: 8px 10px;
            position: relative;
            max-width: 350px;
            background-color: #000;
            text-overflow: ellipsis;
            cursor: pointer;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 64px;
        }
    }

    .FiveBannerTwo {
        display: flex;

        .ImageWrapper {
            position: relative;
            width: 50%;
        }

        .five-img {
            width: 100%;
            height: 100%;
        }

    }

    .FiveBannerFull {
        max-width: 1830px;
        margin: auto;

        .ImageWrapper {
            position: relative;
        }

        .five-img {
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width:768px) {
    .BrandBlockFiveBanner {
        .FiveBannerTwo {
            flex-direction: column;

            .ImageWrapper {
                width: 100%;
            }
        }

        .BrandImgContent {
            left: 15px;

            .Gallery__title {
                font-size: 12px !important;
            }

            .img-desc {
                font-size: 12px !important;
            }
        }
    }
}