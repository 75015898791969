.payment {
    margin-top: 1em;
    display: flex;
    flex-direction: column;

    & > div {
        position: relative;
        z-index: 0;
    }

    .payment__option {
        display: flex;
        align-items: center;
        gap: 2em;
        width: max-content;

        input[type="radio"] {
            height: 20px;
            width: 20px;
        }

        span {
            display: flex;
            gap: 1em;

            img {
                max-height: 5em;
                height: 100%;
                width: 100%;
                filter: grayscale(100%);
            }

            .payment__selected {
                filter: grayscale(0%);
            }
        }
    }
    .redsys__order {
        background-color: #E08033;
        cursor: pointer;
        color: white;
        padding: .5em 1.2em;
        border-radius: 4px;
        max-width: 750px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55px;
        margin: 0;
    }
    .payment__option2 {
        span{
            img {
                min-height: 9em;
            }
        }
    }
}