* {
  font-family: 'Lexend', sans-serif;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

.mb2 {
  margin-bottom: 2rem !important;
}

a {
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.sec__container {
  max-width: 1830px;
  margin: auto;
}

.Main__section {
  margin-top: 99px !important;
  min-height: 62vh;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #FFF;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #FFF;
  overflow-x: auto;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #a4a4a4;
  overflow-x: auto;
}

input:hover,
input:focus,
input:active {
  outline: none;
}

.slick-slider {
  width: 90% !important;
  margin: 0px auto !important;
}

.slick-slider {

  .slick-arrow::before {
    display: none;
  }

  .slick-arrow:hover {
    background-color: #0da5e8d4;
  }

  .slick-prev {
    display: flex !important;
    left: 0%;
    background-color: #dadada9e;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    svg {
      color: #FFF;
      width: 20px;
      height: 20px;
      display: block;
      stroke-width: inherit;
      margin-left: -3px;
    }
  }

  .slick-next {
    display: flex !important;
    right: 0%;
    background-color: #dadada9e;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    svg {
      color: #FFF;
      width: 20px;
      height: 20px;
      display: block;
      stroke-width: inherit;
      margin-right: -3px;
    }
  }

  .slick-dots {
    bottom: 3%;

    li button::before {
      content: '';
      width: 12px;
      height: 12px;
      border: 1px solid #2b2b2b;
      border-radius: 50%;
      opacity: 1;
      background-color: #FFF;
    }

    li.slick-active button::before {
      background-color: #2b2b2b;
    }
  }
}

.CookieConsent {
  display: block !important;
}

.CookieConsent div {
  max-width: 1680px;
  padding: 0 15px;
  margin: auto !important;
}

.CookieConsent__block a {
  text-decoration: underline;
}

.imagesUploadSoon {
  text-align: center;
  font-size: 29px;
  text-shadow: 4px 3px 19px rgba(255, 0, 0, 1);
  padding-bottom: 25px;
  padding-top: 25px;
}

.Gallery__title {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
}

.img-desc{
  -webkit-line-clamp: 1 !important;
}

@media only screen and (max-width: 1680px) {
  .sec__container {
    max-width: 100%;
    margin: auto 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .slick-slider {

    .slick-prev,
    .slick-next {
      width: 40px;
      height: 40px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .Main__section {
    margin-top: 117px !important;
  }
}

@media only screen and (max-width: 600px) {
  .Main__section {
    margin-top: 141px !important;
  }

  .Gallery__title,
  .Gallery__desc,
  .img-desc {
    line-height: 28px !important;
  }
}

@media only screen and (max-width: 475px) {
  .Main__section {
    margin-top: 146px !important;
  }
}

@media only screen and (max-width: 360px) {
  .Main__section {
    margin-top: 149px !important;
  }
}

@media only screen and (max-width:992px) {
  .Gallery__title {
    font-size: 12px !important;
  }

  .Gallery__desc {
    font-size: 12px !important;
  }
}