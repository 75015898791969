$defaultColor: #0da5e8;
.orders {
    .MobileView {
        display: none;
    }
    .orders__heading {
        background-color: #fff;
        margin-bottom: 1em;
        p {
            margin: 0;
            padding: 10px;
            border: 1px solid #d2d2d2;
            // border-radius: 10px;
        }
    }
    label {
        width: 100%;
        position: relative;
        input {
            width: 100%;
            padding-left: 12px;
            height: 40px;
            border: none;
        }
        span {
            position: absolute;
            right: 2px;
            bottom: 2px;
            top: 2px;
            height: 35px;
            width: 35px;
            background-color: #536bf6;
            cursor: pointer;

            svg{
                position: absolute;
                top: 10px;
                width: 100%;
            }
        }
    }
    .orders__container {
        display: flex;
        flex-wrap: wrap;
        .asc {
            color: $defaultColor;
        }
        .desc {
            color: $defaultColor;
        }
        & > * {
            background-color: #f1f1f1;
        }
        .orders__list {
            // border: 1px solid lightgray;
            // box-shadow: -8px -6px rgba(39,84,170,.161);
            padding: 10px;
            margin-top: 3em;
            .orders__table {
                display: block;
                max-height: 450px;
                overflow-y: auto;
                thead {
                    background-color: white;
                    font-size: 14px;
                    tr {
                        td {
                            text-align: center;
                            font-size: 14px;
                            font-weight: 600;
                            padding: 10px;
                            border: 1px solid #f3f3f3;
                            cursor: pointer;
                        }
                    }
                }
                tbody {
                    border-top: 2px solid $defaultColor;
                    border-bottom: 2px solid $defaultColor;
                    background-color: #f3f3f3;
                    font-size: 14px;
                    max-height: 450px;
                    overflow-y: auto;
                    // display: block;
                    tr {
                        cursor: pointer;
                        td {
                            padding: 10px;
                            border: 1px solid white;
                            text-align: center;
                        }
                    }

                    .order__selected {
                        background-color: $defaultColor;
                        color: white;
                    }
                }
            }
        }

        .orders_delivary_dates{
            padding: 10px;
            margin-top: 3em;

            thead {
                background-color: white;
                font-size: 14px;
                tr {
                    td {
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 10px;
                        border: 1px solid #f3f3f3;
                        cursor: pointer;
                    }
                }
            }

            tbody {
                background-color: #f3f3f3;
                font-size: 14px;
                border-top: 2px solid $defaultColor !important;
                border-bottom: 2px solid $defaultColor !important;
                tr {
                    td {
                        padding: 10px;
                        border: 1px solid white;
                        text-align: center;
                        img {
                            max-width: 4em;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            .estimated_delivery_row {
                cursor: pointer;
            }
        }

        .orders__details {
            flex: 1;
            // border: 1px solid lightgray;
            // box-shadow: 8px 6px rgba(39,84,170,.161);
            padding: 10px;
            margin-top: 3em;
            .details__table {
                width: 100%;
                margin: 0 auto;
            }

            thead {
                background-color: white;
                font-size: 14px;
                tr {
                    td {
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 10px;
                        border: 1px solid #f3f3f3;
                        cursor: pointer;
                    }
                }
            }
            tbody {
                background-color: #f3f3f3;
                font-size: 14px;
                border-top: 2px solid $defaultColor !important;
                border-bottom: 2px solid $defaultColor !important;
                tr {
                    td {
                        padding: 10px;
                        border: 1px solid white;
                        text-align: center;
                        img {
                            max-width: 4em;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        .table__address {
            display: flex;
            align-items: baseline;
            padding: 1em 0;
            gap: 1em;

            h4 {
                width: max-content;
                border-bottom: 4px solid $defaultColor;
            }

            & > * {
                padding: 1em;
                width: calc(100% / 2 - 20px);
                border: 1px solid $defaultColor;
            }

            p {
                margin: 0;
            }
        }

        .progress_status {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            p {
                display: flex;
                flex-direction: column;
                margin:0;
            }
        }
    }
}

@media(max-width: 920px) {
    .MobileView {
        display: block !important;
        font-size: 14px;
        font-weight: 600;
    }
    .orders .orders__container .orders__details {
        overflow: scroll;
    }
    .orders__container {
        flex-direction: column-reverse;
        & > * {
            width: 100%;
            margin-top: 1em !important;
            box-shadow: none !important;
        }

        .table__address {
            flex-direction: column;

            & > * {
                width: 100% !important;
            }
        }
    }
}

@media(max-width: 580px) {
    .orders__table {
        thead {
            display: none;
        }
    }
    .orders .orders__container .table__address h4 {
        font-size: 16px;
    }

    tbody {
        background-color: white !important;
        tr {
            background-color: #f3f3f3;
            margin-bottom: 1em;
            // td {
            //     display: flex !important;
            //     justify-content: space-between;
            // }
        }
    }
    .table tr {
        display: block;
        margin-bottom: 10px;
      }
    .table th,
    .table td {
    display: block;
    text-align: right;
    }
    .table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    }
}