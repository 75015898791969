$defaultColor: #0da5e8;
.invoices {
    .invoices__heading {
        margin-bottom: 1em;
        // p {
            // margin: 0;
            padding: 10px;
            border: 1px solid #d2d2d2;
            border-radius: 10px;
        // }
    }

    label {
        width: 100%;
        position: relative;
        input {
            width: 100%;
            padding-left: 12px;
            height: 40px;
        }
        span {
            position: absolute;
            right: 2px;
            bottom: 2px;
            top: 2px;
            height: 35px;
            width: 35px;
            background-color: #536bf6;
            cursor: pointer;

            svg{
                position: absolute;
                top: 10px;
                width: 100%;
            }
        }
    }

    .invoices__container {
        .invoices__table {
            width: 100%;
            margin-top: 1em;
            thead {
                background-color: white;
                font-size: 14px;
                tr {
                    td {
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 10px;
                        border: 1px solid #f3f3f3;
                        cursor: pointer;
                    }
                }
            }
            tbody {
                background-color: #f3f3f3;
                font-size: 14px;
                max-height: 450px;
                overflow-y: auto;
                tr {
                    td {
                        padding: 10px;
                        border: 1px solid white;
                        text-align: center;
                    }
                }
            }
        }
        .asc {
            color: $defaultColor;
        }
        .desc {
            color: $defaultColor;
        }
        .invoices__pagination {
            margin-top: 1em;
            .pagination {
                justify-content: flex-end;
            }
            ul {
                margin: 0;
                li {
                    margin: 0 1px;
                    width: 30px;
                    height: 30px;

                    a {
                        color: $defaultColor;
                        background-color: white;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $defaultColor;
                    }
                }
                .disabled {
                    display: none;
                }
                .active {
                    a {
                        background-color: $defaultColor;
                        color: white;
                    }
                }
            }
        }
    }

    .invoices__pdf {
        svg {
            cursor: pointer;
            color: red;
            width: 24px;
            height: 24px;
        }
    }
}