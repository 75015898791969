$defaultColor: #0da6e9;

.error {
  font-size: 16px;
  color: red;
  padding-left: 10px;
}

.success {
  font-size: 16px;
  color: #37bfa7;
  padding-left: 10px;
}

@mixin flexBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin B2BButton {
  background-color: #f1f1f1;
  color: $defaultColor;
  @include flexBox();
  flex-direction: column;
  width: 100%;
  min-height: 10em;
  padding: 10px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: $defaultColor;
    color: white;
  }
}

.register {

  p,
  h2,
  h3 {
    margin: 0;
    padding-bottom: 5px;

  }

  // min-height: 80vh;

  .register__container {
    gap: 1em;
    max-width: 1680px;
    width: 100%;
    margin: auto;
    padding: 6em 0;

    @include flexBox;
    align-items: unset !important;

    .register__content {
      @include flexBox;
      gap: 1em;
      height: max-content;
      align-items: flex-end;

      img {
        height: 60px;
        object-fit: contain;
      }

      &>* {
        width: calc(100% / 2 - 10px);
      }

      .content__B2C,
      .content__B2B {
        min-height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .Continue__guest {
          display: none;
        }

        h2 {
          min-height: 60px;
          font-size: 24px;
          color: $defaultColor;
        }

        .B2C__description,
        .B2B__description {
          color: #afafaf;
          font-size: 14px;
          min-height: 100px
        }

        .B2C__selection {
          @include B2BButton;
          margin: 0
        }

        .B2B__selection {
          margin: 0;
          @include flexBox;
          gap: 1em;

          .B2B__shop,
          .B2B__wholesaler {
            @include B2BButton;
            width: calc(100% / 2 - 10px);
          }
        }
      }
    }

    .register__form {
      padding: 1em;
      background-color: #F8F9FA;
      // border: 1px solid #2b2b2b;
      height: max-content;
      box-shadow: 8px 6px rgba(39, 84, 170, .1607843137254902);
      min-height: calc(450px - 16px);

      h3,
      h2 {
        font-size: 20px;
        color: $defaultColor;
        text-align: center;
        margin-bottom: 1em;
      }

      h3 {
        color: #2b2b2b;
      }

      .register__login {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &>input {
          margin: 10px 0;
          height: 36px;
          width: 100%;
          padding-left: 10px;
          background-color: #E8F0FE;
          border: none;
        }

        .register__button {
          width: 100%;
        }

        label {
          display: flex;
          width: max-content;
          margin: 10px 0;
          cursor: pointer;
        }

        input {
          font-size: 16px;

          &:focus {
            outline: none;
          }
        }

        .register__button {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          button {
            background-color: #000;
            color: #fff;
            padding: 10px 3em;
          }

          label input {
            margin-right: 10px;
          }
        }
      }

      .register__hideforgot {
        visibility: hidden;
        height: 0px;
      }

      .register__forgotpass {
        height: max-content;
        visibility: visible;
        height: max-content;
        transition: height 2s;
        border-top: 1px solid $defaultColor;

        .forgotpass__actions {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          input {
            margin: 10px 0;
            height: 36px;
            width: 100%;
            padding-left: 10px;
            background-color: #E8F0FE;
            border: none;
            ;
          }

          button {
            background-color: #000;
            color: #fff;
            padding: 10px 3em;
          }
        }
      }
    }
  }
}

@media(max-width: 1680px) {
  // .Main__section {
  //   padding: 0 1em;
  // }

  .register {
    .register__container {
      gap: 1em;

      .register__form {
        min-width: 35%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .register {
    .register__container {
      flex-direction: column;
      gap: 3em;
    }
  }
}

@media(max-width: 812px) {
  .register {
    .register__container {
      .register__content {
        flex-direction: column;

        &>* {
          width: 100% !important;
        }
      }
    }
  }
}

@media(max-width: 480px) {
  .register {
    .register__container {
      .register__content {
        .content__B2C {
          border-bottom: 1px solid $defaultColor;
        }
      }

      .register__form {
        .register__login {
          .register__button {
            button {
              margin: 0 auto;
            }
          }
        }

        .register__forgotpass {
          .forgotpass__actions {
            button {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 420px) {
  .B2B__selection {
    flex-direction: column;

    &>* {
      width: 100% !important;
    }
  }

  .B2B__title {
    margin: 1em 0 !important;
  }
}