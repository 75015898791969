.OrderSummery__warp {
    .OrderSummery__title {
        h1 {
            font-size: 32px;
            color: #0da5e8;
            font-weight: 500;
            margin-bottom: 35px;
            text-transform: capitalize;
        }
    }

    .OrderSummery__section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .CartListing__list {
            width: 75%;

            .CartTable {

                .CartTable__head {
                    display: flex;
                    border: 1px solid #dedede;
                    background-color: #dedede;
                    .CartTable__head__title {
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #FFF;
                        text-transform: capitalize;
                        
                        &:last-child {
                            border: none;
                        }

                        span {
                            font-size: 18px;
                            color: #2b2b2b;
                            font-weight: 500;
                        }
                    }

                    
                }

                .CartTable__row {
                    display: flex;
                    border: 1px solid #dedede;
                    border-top: none;

                    .immediate_service {
                        width: 100%;
                        background-color: #65C24D;
                        padding: 12px;
                        text-align: center;
                        .label{
                            text-transform: uppercase;
                            color: #FFF;
                        }
                    }

                    .product_immediate_service {
                        background-color: #65C24D;
                        border-radius: 22px;
                        padding: 8px 12px;
                        color: #FFF;
                        font-size: 14px;
                        width: 100%;
                        text-align: center;
                        margin-top: 10px;
                    }

                    .pre_service {
                        width: 100%;
                        background-color: red;
                        padding: 12px;
                        text-align: center;
                        .label{
                            text-transform: uppercase;
                            color: #FFF;
                        }
                    }

                    .product_pre_service {
                        background-color: red;
                        border-radius: 22px;
                        padding: 8px 12px;
                        color: #FFF;
                        font-size: 14px;
                        width: 100%;
                        text-align: center;
                        margin-top: 10px;
                    }

                    .CartTable__row_col {
                        padding: 25px 10px;
                    }

                    .CartTable__prod {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .Product__Image {
                            width: 22%;

                            img {
                                width: 100%;
                                height: auto;
                            }
                        }

                        .Product__Details {
                            width: 74%;

                            .Product__Name {
                                display: block;
                                color: #2b2b2b;
                                margin-bottom: 7px;
                            }

                            .Product__Sku {
                                .label {
                                    font-weight: 600;
                                    color: #2b2b2b;
                                }
                            }
                        }
                    }

                    .CartTable__action,
                    .CartTable__discount,
                    .CartTable__subtotal,
                    .CartTable__client,
                    .CartTable__price {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .currency {
                            margin-left: 5px;
                        }
                    }

                    .CartTable__action {
                        .CartTable__action__delete svg{
                            cursor: pointer;
                            color: #ff0000ad;
                            width: 26px;
                            height: 26px;
                            margin: 8px;
                        }

                        .CartTable__action__update svg{
                            cursor: pointer;
                            color: blue;
                            width: 28px;
                            height: 28px;
                            margin: 2px 8px 0;
                        }
                    }

                    .CartTable__quan {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .CartQuantity {
                            text-align: center;
                        }
                    }
                }

                .CartTable__footer {
                    display: flex;
                    justify-content: right;
                    gap: 25px;
                    padding: 25px 0;

                    .CartTable__footer__action {
                        color: #FFF;
                        background-color: #000;
                        border: none;
                        outline: none;
                        padding: 12px 50px;
                        border-radius: 4px;

                        &:hover {
                            background-color: #0da5e8;
                        }
                    }
                }

                .CartTable__prod {
                    width: 34%;
                }

                .CartTable__price {
                    width: 13%;
                }

                .CartTable__discount {
                    width: 13%;
                }

                .CartTable__client {
                    width: 13%;
                }

                .CartTable__quan {
                    width: 13%;
                }

                .CartTable__subtotal {
                    width: 13%;
                }

                .CartTable__action {
                    width: 10%;
                }
            }
        }

        .Price__summery {
            width: 23%;
            height: max-content;

            .Price__details__block {
                background-color: #dceff7;
                border: 1px solid #0da5e8;
                padding: 10px;
                margin-bottom: 20px;
    
                .Price__details__title {
                    color: #FFF;
                    background-color: #0da5e8;
                    padding: 10px;
                    text-align: center;
                }

                .Price__details__body {
                    border-bottom: 1px solid #0da5e8;

                    .row {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 15px 1px;
    
                        .label {
                            display: block;
                            width: 70%;
                            text-align: right;
                        }
    
                        .price {
                            display: block;
                            width: 30%;
                            text-align: right;
                        }
    
                        .currency {
                            margin-left: 5px;
                        }
                    }
                    
                }



                .Price__details__footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 1px 5px;

                    .label {
                        display: block;
                        width: 70%;
                        text-align: right;
                        font-weight: 600;
                    }

                    .price {
                        display: block;
                        width: 30%;
                        text-align: right;
                        font-weight: 600;
                    }

                    .currency {
                        margin-left: 5px;
                    }
                }

            }

            .Price__details__nav {
                margin-top: 25px;

                & > div {
                    position: relative;
                    z-index: 0;
                }

                .CartSummeryNav {
                    color: #FFF;
                    background-color: #000;
                    border: none;
                    outline: none;
                    width: 100%;
                    padding: 12px;
                    font-size: 18px;
                    border-radius: 4px;
                    margin-bottom: 1em;

                    &:hover {
                        background-color: #0da5e8;
                    }
                }
            }

            .TermsCondition__block {
                margin: 25px 0;
                display: flex;
                gap: 10px;
                color: #2b2b2b;

                a {
                    color: #0da5e8;
                }
            }

            .TermsCondition__error {
                color: red;
            }
            
        }

        .Address__summery {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 30px;

            .Address__summery__block {
                width: 48%;
                padding: 10px 15px;
                border: 1px solid #dedede;

                .Address__summery__title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #2b2b2b;
                    margin-bottom: 8px;

                    display: flex;
                    justify-content: space-between;

                    svg {
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }

                .Address__summery__body {
                    .label {
                        color: #2b2b2b;
                    }
                }
            }
        }
    }

    .OrderSummery__payment {
        margin-top: 25px;
        width: 75%;

        .OrderSummery__payment_title {
            font-size: 32px;
            color: #0da5e8;
            font-weight: 500;
            margin-bottom: 0px;
            text-transform: capitalize;
            border-bottom: 1px solid;
        }
    }
}

.OrderSummery__warp {
    .OrderSummery__section {
        .CartListing__list {
            .CartTable {
                .CartTable__head {
                    .CartTable__head__title.CartTable__price_discount{
                        display: none;
                    }
                }

                .CartTable__row {
                    .CartTable__row_col.CartTable__price_discount {
                        display: none;
                    }

                    .Product__Details_row {
                        display: none;
                    }
                }
            }
        }
    } 
} 

@media only screen and (max-width: 1600px) {
    .OrderSummery__warp { 
        .OrderSummery__section {
            .CartListing__list {
                width: 70%;
            }

            .Price__summery {
                width: 28%;
            }
        }

        .OrderSummery__payment {
            width: 70%;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .OrderSummery__warp { 
        .OrderSummery__title h1 {
            font-size: 28px;
        }

        .OrderSummery__section {

            .CartListing__list {
                width: 100%;
            }

            .Price__summery {
                margin-top: 2rem;
                width: 100%;
            }
        }

        .OrderSummery__payment {
            .OrderSummery__payment_title {
                font-size: 28px;
            }

            width: 100%;
        }
    }
}

@media only screen and (max-width: 992px) {
    .OrderSummery__warp {
        .OrderSummery__section {
            .CartListing__list {
                .CartTable {
                    .CartTable__head {
                        .CartTable__head__title.CartTable__price,
                        .CartTable__head__title.CartTable__discount,
                        .CartTable__head__title.CartTable__client {
                            display: none;
                        }

                        .CartTable__head__title.CartTable__price_discount{
                            display: flex;
                            width: 35%;
                        }
                    }

                    .CartTable__row {
                        .CartTable__row_col.CartTable__price,
                        .CartTable__row_col.CartTable__discount,
                        .CartTable__row_col.CartTable__client {
                            display: none;
                        }

                        .CartTable__row_col.CartTable__price_discount {
                            display: flex;
                            flex-wrap: wrap;
                            width: 35%;

                            .row_incal{
                                width: 95%;
                                margin: auto;
                                display: flex;

                                .label {
                                    width: 137px;
                                    font-weight: 600;
                                    display: block;
                                }
                            }
                        }
                    }

                    .CartTable__prod {
                        width: 35%;
                    }

                    .CartTable__quan {
                        width: 15%;
                    }

                    .CartTable__subtotal {
                        width: 15%;
                    }
                }
            }
        } 
    }
}


@media only screen and (max-width: 650px) {
    .OrderSummery__warp { 
        .OrderSummery__title h1 {
            font-size: 24px;
        }

        .OrderSummery__section {
            .CartListing__list {
                .CartTable {
                    .CartTable__head {
                        display: none;
                    }

                    .CartTable__row {
                        & > :first-child {
                            border-top: 1px solid #dedede;
                        }

                        .CartTable__row_col.CartTable__prod {
                            width: 100%;

                            .Product__Image {
                                width: 30%;
                            }

                            .Product__Details {
                                width: 67%;

                                .Product__Details_row {
                                    display: block;

                                    .label {
                                        font-weight: 600;
                                        color: #2b2b2b;
                                    }
                                }
                            }
                        }

                        .CartTable__row_col.CartTable__price_discount,
                        .CartTable__row_col.CartTable__quan,
                        .CartTable__row_col.CartTable__subtotal {
                            display: none;
                        }
                    }
                    
                }

                .Address__summery {
                    .Address__summery__block {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }

            .Price__summery {
                margin-top: 2rem;
                width: 100%;
            }
        }

        .OrderSummery__payment {
            .OrderSummery__payment_title {
                font-size: 24px;
            }

            width: 100%;
        }
    }
}


