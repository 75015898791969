.ProductBreadcrumbs{
    max-width: 1680px;
    width: 100%;
    margin: auto;
    padding: 1em 15px;

    li {
        a {
            color: #0DA5E8;
            font-size: 18px;
            text-transform: uppercase;
        }

        p {
            font-size: 16px;
            color: #212529;
            text-transform: uppercase;
            font-weight: 400;
            font-family: "Lexend", sans-serif;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .ProductBreadcrumbs{
       li {
            a {
                font-size: 18px;
            }
    
            p {
                font-size: 18px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .ProductBreadcrumbs{
       li {
            a {
                font-size: 16px;
            }
    
            p {
                font-size: 16px;
            }
        }
    }
}
