$defaultColor: #0da5e8;
.addressbook {

    .AB__MobileView {
        display: none;
    }
    .addressbook__myaddress {
        border: 1px solid #d2d2d2;
        border-radius: 8px;
        padding: 10px;
        height: max-content;
        margin-bottom: 1em;
        background-color: #fff;

        .addressbook__title {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d2d2d2;

            h3 {
                font-size: 18px;
                font-weight: 500;
                margin: 0;
                color: $defaultColor;
            }


            .add__address {
                background-color: #000;
                color: white;
                padding: 10px 2em;
                font-size: 14px;
                display: flex;
                justify-content: center;
                margin: 10px auto;
            }
        }

        .addressbook__default {
            display: flex;
            justify-content: center;

            & > * {
                width: calc(100% / 2 - 10px);
            }
            .addressbook__billing, .addressbook__shipping {
                h4 {
                    font-size: 16px;
                    font-weight: 400;
                    width: max-content;
                    margin: 1em 0;
                    color: $defaultColor;
                    text-decoration: underline;
                }
                p {
                    margin: 0;
                    margin-bottom: 2px;
                    font-weight: 300;
                    width: max-content;
                }
    
                .change__address {
                    text-decoration: underline;
                    cursor: pointer;
                    font-weight: 400;
                    color: $defaultColor;
                    width: max-content;
                }
            }
        }
    }
    .addressbook__otheraddress {
        border: 1px solid #d2d2d2;
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
        
        .addressbook__pagination {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .addressbook__tabletitle {
                color: $defaultColor;
                font-size: 18px;
            }

            ul {
                margin: 0;
                li {
                    margin: 0 1px;
                    width: 30px;
                    height: 30px;

                    a {
                        color: $defaultColor;
                        background-color: white;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $defaultColor;
                    }
                }
                .disabled {
                    display: none;
                }
                .active {
                    a {
                        background-color: $defaultColor;
                        color: white;
                    }
                }
            }
        }
        .address__success {
            color: #37bfa7;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
        }
        .addressbook__table {
            width: 100%;
            thead {
                tr {
                    background-color: white;
                    td {
                        font-size: 14px;
                        padding: 10px;
                        border: 1px solid #f3f3f3;
                        text-align: center;
                        background-color: $defaultColor;
                        color: white;
                    }
                }
            }
            tbody {
                background-color: #f1f1f1;
                tr {
                    background-color: #f3f3f3;
                    td {
                        font-size: 14px;
                        padding: 10px;
                        border: 1px solid white;
                        text-align: center;
                    }
    
                    .addressbook__table__buttons {
                        display: grid;
                        .addressbook__edit {
                            color: $defaultColor;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                        .addressbook__delete {
                            color: $defaultColor;
                            text-decoration: underline;
                            border-top: 1px solid white;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width: 920px) {
    .addressbook__otheraddress {
        width: 100%;
        overflow-x: scroll;
        .addressbook__table {
            .addressbook__table__buttons {
                display: flex !important;
                justify-content: center;
                align-items: center;
                gap: 1em;
    
                span {
                    color: $defaultColor;
                    text-decoration: underline;
                }
            }
        }
    }
}

@media(max-width: 767px) {
    
}

@media(max-width: 580px) {
    .addressbook {
        .addressbook__myaddress {
            .addressbook__default {
                flex-direction: column;
            }
        }
    }
    .AB__MobileView {
        display: block !important;
    }
    .table tr {
        display: block;
        margin-bottom: 10px;
      }
    .table th,
        .table td {
            display: block;
            text-align: right;
    }
    .table td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
}