.Submenu__section {
    border: none;
    border-top: 10px solid #0da5e8 !important;
    border-radius: 0;
    color: #fff;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0px;
    padding: 15px  !important;
    margin-top: 15px;
    background: url('../../../assects/images/Header/dropdown-menu-bg.png');

    .Submenu__row {
        display: flex;
        max-height: 362px;
        overflow-x: hidden;
        overflow-y: auto;

        .Submenu__list {
            width: 70%;
            display: flex;
            flex-wrap: wrap;

            .Submenu__list__block {
                margin: 0;
                padding: 0 0 0 20px;
                margin-bottom: 30px;
                width: 16%;

                .Submenu__link.Submenu__list__head {
                    margin-bottom: 18px;
                    a {
                        color: #0DA5E8;
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
                
                .Submenu__link {
                    &::marker {
                        content: none;
                    }
                    a {
                        color: #FFF;
                        list-style: none;
                        font-size: 14px;
                        text-transform: uppercase;
                        line-height: 20px;
                        word-break: break-all;
                        margin-bottom: 10px;
                        display: block;
                    }
                }
            }
        }

        .Submenu__products {
            width: 30%;
            border-left: 1px solid #FFF;
            padding-left: 22px;

            .Submenu__products__title {
                color: #0DA5E8;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 18px;
            }

            .Submenu__products__menu {
                margin: 0;
                padding: 0;
                margin-bottom: 30px;
                column-count: 3;
                column-gap: 40px;

                .Submenu__link.Submenu__list__head {
                    margin-bottom: 18px;
                    a {
                        color: #0DA5E8;
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
                
                .Submenu__link {
                    &::marker {
                        content: none;
                    }
                    a {
                        color: #FFF;
                        list-style: none;
                        font-size: 14px;
                        text-transform: uppercase;
                        line-height: 20px;
                        word-break: break-all;
                        margin-bottom: 10px;
                        display: block;
                    }
                }
            }
        }

        .Brand__section {
            width: 100%;
            background: transparent;

            .Brand__Block {
                padding: 0;
    
                .Brand__content {
                    margin-bottom: 0px !important;
                    display: none !important;
                }

                .Brand__slider__block {
                    width: 100%;
                    margin: auto;
                    max-width: 55%;
                    padding: 20px;

                    .slider__block__col {
                        width: 30%;

                        img {
                            width: 100%;
                        }

                        .brand__parent .brand__child {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}