.FAQ__section {
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 7px 7px;
    background: #e2e2e2 url("../../../assects/images/home/goku.png") right bottom no-repeat;

    .FAQ__block {
        max-width: 1680px;
        width: 100%;
        margin: auto;
        padding: 5em 15px;

        .FAQ__content {
            text-align: center;
            max-width: 900px;
            margin: auto;

            .FAQ__title {
                letter-spacing: 0px;
                color: #0DA5E8;
                text-transform: uppercase;
                font-size: 32px;
                line-height: 40px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .FAQ__text {
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0px;
                color: #2b2b2b;
            }
        }

        .FAQ__according {
            max-width: 70%;
            margin: auto;

            .accordion-cont {
                &>div {
                    box-shadow: none;
                    border-radius: inherit;
                    background-color: transparent;
                    border-bottom: 1px solid #707070;
                }

                .faqheading {
                    letter-spacing: 0px;
                    color: #0DA5E8;
                    font-size: 26px;
                }

                .expandIconWrapper,
                .collapsIconWrapper {
                    font-size: 28px;
                    line-height: 10px;
                    color: #0DA5E8;
                }
            }
        }
    }

    .contactusParent {
        text-align: center;
        padding-top: 2rem;
    }
}

.Category__container {
    .FAQ__section {
        background: url("../../../assects/images/home/background-Grupo-81-left.png") left bottom no-repeat,
            url("../../../assects/images/home/background-Grupo-81-right.png") right top no-repeat;
        background-color: #FFF;

        .FAQ__block {
            padding: 5em 15px 7em;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .FAQ__section {
        .FAQ__block {
            padding: 2em 15px;

            .FAQ__content {
                margin: 2rem auto;

                .FAQ__title {
                    font-size: 28px;
                    line-height: 36px;
                }

                .FAQ__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }

    }

    .Category__container {
        .FAQ__section {
            background-size: 25%;

            .FAQ__block {
                padding: 3em 15px 4em;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .FAQ__section {
        .FAQ__block {
            .FAQ__according {
                max-width: 90%;

                .accordion-cont .faqheading {
                    font-size: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .FAQ__section {
        .FAQ__block {
            .FAQ__content {
                .FAQ__title {
                    font-size: 24px;
                    line-height: 32px;
                }

                .FAQ__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }

    }
}

@media only screen and (max-width: 768px) {
    .FAQ__section {
        .FAQ__block {
            .FAQ__according {
                .accordion-cont .faqheading {
                    font-size: 20px;
                }
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .FAQ__section {
        .FAQ__block {
            .FAQ__content {
                margin: 2rem auto;

                .FAQ__title {
                    font-size: 22px;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .FAQ__section {
        .FAQ__block {
            .FAQ__content {
                margin: 2rem auto;

                .FAQ__title {
                    font-size: 22px;
                }
            }

            .FAQ__according {
                max-width: 95%;
            }
        }
    }
}