.Brand__section {
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 7px 7px;
    background-color: #FFF;
    overflow: hidden;

    .Brand__Block {
        max-width: 1680px;
        width: 100%;
        margin: auto;
        padding: 5em 15px;

        .Brand__content {
            text-align: center;
            max-width: 900px;
            margin: auto;

            .Brand__title {
                letter-spacing: 0px;
                color: #0DA5E8;
                text-transform: uppercase;
                font-size: 32px;
                line-height: 40px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .Brand__text {
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
                letter-spacing: 0px;
                color: #2b2b2b;
            }
        }

        .Brand__slider__block {
            display: flex;
            justify-content: space-between;

            .slider__block__col{
                .brand__parent {
                    position: relative;
                    display: inline-block;
                    background-size: cover;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0);
                        transition: 0.3s;
                    }

                    &:hover {
                        .brand__child { 
                            opacity: 1;
                        }

                        &::after {
                            background: rgba(0, 0, 0, 0.8);
                        }
                    }

                    .brand__child {
                        font-size: 30px;
                        font-weight: bold;
                        text-align: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 80%;
                        transform: translate(-50%, -50%);
                        z-index: 5;
                        color: white;
                        opacity: 0;
                        transition: 0.5s;
                        line-height: 2.5rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1680px) {
    .Brand__section {
        .Brand__Block {
            .Brand__slider__block {
                justify-content: space-evenly;
                .slider__block__col{
                    width: 30%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .Brand__section {
        .Brand__Block {
            .Brand__content {
                margin: 2rem auto;
                .Brand__title {
                    font-size: 28px;
                    line-height: 36px;
                }
        
                .Brand__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            .Brand__slider__block {
                .slider__block__col{
                    .brand__parent{
                        .brand__child {
                            font-size: 24px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .Brand__section {
        .Brand__Block {
            .Brand__slider__block {
                .slider__block__col{
                    .brand__parent{
                        .brand__child {
                            font-size: 20px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .Brand__section {
        .Brand__Block {
            .Brand__content {
                .Brand__title {
                    font-size: 24px;
                    line-height: 32px;
                }
        
                .Brand__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .Brand__section {
        .Brand__Block {
            padding: 1em 15px 3em;
            .Brand__slider__block {
                .slider__block__col{
                    .brand__parent{
                        .brand__child {
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .Brand__section {
        .Brand__Block {
            .Brand__content {
                margin: 2rem auto;
                .Brand__title {
                    font-size: 22px;
                }
            }

            .Brand__slider__block {
                flex-wrap: wrap;

                .slider__block__col{
                    width: 60%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .Brand__section {
        .Brand__Block {
            .Brand__content {
                margin: 2rem auto;
                .Brand__title {
                    font-size: 22px;
                }
            }

            .Brand__slider__block {
                flex-wrap: wrap;

                .slider__block__col{
                    width: 100%;
                }
            }
        }
    }
}