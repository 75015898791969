.skeleton__product__block{
    box-shadow: rgba(99, 99, 99, 30%) 0px 2px 8px 0px;
    position: relative;
    overflow: hidden;
    padding: 10px;
    width: 320px;

    .skeleton__product__img__block {
        position: relative;

        .skeleton__product__img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            opacity: 0.2;
            transform: translate(-50%, -50%);

            .skeleton__product__img img {
                width: 100px;
                height: 100px;
            }
        }
    }

    .skeleton__product__desc {
        margin: 10px 0 15px;

        .skeleton__product__row {
            display: flex;
            margin-bottom: 3px;
            padding-left: 5px;

            .skeleton__product__name {
                width: 75%;
                font-size: 14px;
                padding-right: 5px;
                min-height: 42px;
            }

            .skeleton__product__sku {
                display: flex;
                justify-content: center;
                width: 25%;
                padding: 0 5px;
            }

            .distributor__price,
            .skeleton__product__brand {
                width: 55%;
                padding: 5px 0;
            }

            .skeleton__product__discount {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%;
                padding: 5px;
            }

            .skeleton__product__price {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25%;
                padding: 5px;
            }
        }

        .distributor__row {
            .distributor__price {
                color: #FFF;
            }
            .skeleton__product__discount {
                background-color: #FFF;
            }
        }
    }
}