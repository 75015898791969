.Footer__container{
  box-shadow: #00000059 0px -7px 5px;

  .Footer__content {
    background-color: #5D5D5D;
    padding: 50px 0;
    width: 100%;

    .container{
      max-width: 1680px;
      width: 100%;
      margin: auto;
      padding: 0 15px;
      color: #fff;

      .footer-phone {
        border-bottom: 2px solid #fff;
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 8px;
        padding-bottom: 0;
        margin-top: 8px;
      }

      .footer-company {
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
      }

      .footer-address {
        font-size: 18px;
        line-height: 26px;
        white-space: pre;
      } 

      .footer-heading {
        border-bottom: 2px solid #fff;
        color: #0da5e8;
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
        margin-top: 20px;
      }

      .footer-list {
        font-size: 18px;
        line-height: 26px;
        padding: 10px 0 0;
      }

      .footer-list a {
        color: #fff;
        text-decoration: none;
      }

      .social-media {
        margin: 0;
        padding: 0;
        li {
          float: left;
          list-style-type: none;
          margin: 0 30px 20px 0!important;
        }
      } 
    }
  }

  .Footer__Empty {
    background-color: #000000;
    min-height: 50px;
  }
}

.WelcomeModel__Section {
  .WelcomeModel__block {
    position: fixed;
    bottom: 4rem;
    right: 3rem;
    z-index: 1;
    width: 80px;
    height: 80px;
    cursor: pointer;

    .WelcomeModel__Tooltip {
      --r: 10px;
      --h: 28px;
      --p: 50%;
    
      padding: var(--r);
      border-bottom: var(--h) solid #0000;
      --m: conic-gradient(from 26deg at var(--p) 100%,#0000,#000 1deg 30deg,#0000 31deg) 0 100%/100% calc(100% - var(--r)) no-repeat,
        conic-gradient(at calc(var(--r)/2) calc(var(--r)/2),#000 270deg,#0000 0) 0 0/calc(100% - var(--r)/2) calc(100% - var(--r)/2) padding-box,
        radial-gradient(50% 50%,#000 98%,#0000) 0 0/var(--r) var(--r) space padding-box;
      -webkit-mask: var(--m);
              mask: var(--m);
      margin: 0px auto;
      width: 115px;
      font-size: 16px;
      background: linear-gradient(135deg,#f29900,#f07a00, #ee5400) border-box;
      color: #fff;
      position: absolute;
      top: -55px;
      right: -16px;

      display: none;
      align-items: center;
      justify-content: center;
    }

    .WelcomeModel__img {
      width: 100%;
      height: 100%;
    }

    .WelcomeModel__img:hover + .WelcomeModel__Tooltip {
      display: flex;
    }
  }
}

.WelcomeModel__popup {
  .modal-content {
    display: flex;
    flex-direction: inherit;
    border: none;

    .modalLeft {
      width: 50%;
      background-color: #0da5e8;
      border-radius: 7px 0px 0px 7px;
      padding: 35px;

      .modalClose {
        display: none;
      }

      .modelTitle {
        font-size: 26px;
        font-weight: 600;
        color: #FFF;
        text-align: center;
        margin-bottom: 20px;
      }

      .modelPara {
        font-size: 18px;
        color: #FFF;
      }

      p {
        margin: 0px;
        width: 100%;
      }
    }

    .modalRight {
      width: 50%;
      position: relative;
      padding: 40px 20px;

      .modalClose {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .modalRow-btn {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        border: 1px solid #0da5e8;
        border-radius: 5px;
        background-color: #FFF;
        color: #0da5e8;
        outline: none;
        position: relative;

        svg {
          position: absolute;
          left: 10px;
          top: 20%;
          display: block;
          width: 25px;
          height: 25px;
        }
      }

      .modalRow-btn:hover {
        color: #FFF;
        background-color: #0da5e8;
      }
    }
  }
}

@media only screen and (max-width: 1322px) {
  .Footer__container{
    .Footer__content {
      .container{
        .footer-phone {
          font-size: 26px;
        }
      }
    }
  }
}

@media (min-width:992px) and (max-width: 1150px) {
  .Footer__container{
    .Footer__content {
      .container{
        .footer-phone {
          font-size: 22px;
        }
        .footer-heading{
          font-size: 22px;
        }
        .footer-list{
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {

  .WelcomeModel__Section {
    .WelcomeModel__block {
      bottom: 2rem;
      width: 60px;
      height: 60px;
    }
  }

  .WelcomeModel__popup .modal-content {
    flex-direction: column;

    .modalLeft{
      width: 100%;
      border-radius: 7px 7px 0px 0px;
      position: relative;

      .modalClose {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;

        .btn-close {
          background: transparent;

          svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    .modalRight {
      width: 100%;

      .modalClose {
        display: none;
      }
    }
  }
}
