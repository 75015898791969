.Header__Section{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9;

    .Header__top {
        background-color: #929292;

        .Header__container {
            max-width: 1680px;
            width: 100%;
            margin: auto;
            padding: 0 15px;
        }

        .Header__txt {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

        .language__dropdown {
            cursor: pointer;
            .dropdown-menu {
                box-shadow: 0 2px 8px 0 rgba(99,99,99,.3);
            }
        }

        .Header__top__action {
            display: flex;
            align-items: center;
            gap: 20px;

            .aboutus {
                color: #FFF;
                font-size: 14px;
                font-weight: 600;
            }

            .Header__user svg{
                color: #FFF;
            }

            .Header__user {
                .base__user {
                    position: absolute;
                    color: #FFF;
                    background: #0da5e8;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 600;
                    right: -11px;
                    top: -3px;

                    svg {
                        stroke-width: 2px;
                    }
                }

                .dropdown-toggle {
                    cursor: pointer;
                }

                .dropdown-menu {
                    box-shadow: 0 2px 8px 0 rgba(99,99,99,.3);
                    margin-top: 4px;
                }
            }

            .User__auth {
                width: 17px;
                height: 17px;
            }

            .Header__Link {
                margin-right: 15px;
            }
        }
    }

    .Header__bottom {
        background-color: #000000;

        .Header__container {
            max-width: 1680px;
            width: 100%;
            margin: auto;
            padding: 0 15px;

            display: flex;
            align-items: center;

            .Header__logo {
                .navbar__toggler {
                    display: none;
                }
            }

            .Header__menu {
                .Header__menu__navbar {
                    display: flex;
                    padding: 0;
                    margin-left: 20px;
                    position: inherit;
                }

                .nav-link {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: capitalize;
                    color: #FFF;
                    padding: 5px 10px 1.1rem;
                    margin-bottom: -1rem;
                }

                .main__menu__link {
                    &:hover {
                        color: #0DA5E8;
                    }
                }

                .main__menu {
                    position: inherit;

                    .main__menu__link {
                        position: relative;
                    }

                    &:hover .main__menu__link::after {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: -1px;
                        width: 100%;
                        margin-left: -12px;
                        border-top: 6px solid #0DA5E8;
                        border-bottom: 6px solid #0DA5E8;
                        // border-left: 5px solid transparent;
                        // border-right: 5px solid transparent;
                    }

                    &:hover .Submenu__section {
                        display: block;
                    }
                }
            }

            .Header__search {
                margin-left: auto;
            }
        }

        .react-loading-skeleton {
            opacity: 0.3;
        }

        .skeleton__li {
            padding: 0 10px;
        }
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1420px) {
    .Header__menu {
        .Header__menu__block.mobile_search_open {
            .Header__menu__navbar .main__menu:last-child {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1365px) {
    .Header__Section{
        .Header__bottom {
            .Header__container {
                .Header__logo {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .navbar__toggler {
                        display: block;
                        background: none;
                        border: none;
                        outline: none;

                        color: #FFF;

                        svg {
                            width: 30px;
                            height: 30px;
                            margin-right: 20px;
                        }
                    }
                }

                .Header__menu { 
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .Header__top__block {
        flex-wrap: wrap;

        .Header__top__left {
            width: 60%;
        }

        .Header__top__right {
            width: 40%;
            display: flex;
            justify-content: right;
        }
    }
}

@media only screen and (max-width: 767px) {
    .Header__Section .Header__bottom .Header__container {
        height: 67px;
    }
}

@media only screen and (max-width: 600px) {
    .Header__top__block {
        flex-wrap: wrap;

        .Header__top__left {
            width: 100%;
        }

        .Header__top__right {
            width: 100%;
            display: flex;
            justify-content: right;
        }
    }
}

@media only screen and (max-width: 475px) { 
    .Header__top__block .Header__top__right {
        margin-top: 5px;
    }

    .Header__Section .Header__bottom .Header__container .Header__logo {
        width: 80%;

        img {
            width: 100%;
            height: auto;
        }
    }
}