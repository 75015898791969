$defaultColor: #0da5e8;
.accounts {
    position: relative;
    .accounts__heading {
        margin-bottom: 1em;
        background-color: #fff;
        p {
            margin: 0;
            padding: 10px;
            border: 1px solid #d2d2d2;
            border-radius: 10px;
        }
    }
    .accounts__container {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        & > * {
            width: calc(100% / 2 - 10px);
        }
        .accounts__info {
            border: 1px solid #d2d2d2;
            border-radius: 10px;
            height: max-content;
            background-color: #fff;
            
            thead, tbody {
                display: block;
                padding: 10px;
                width: 95%;
                margin: 0 auto;
            }

            thead {
                width: 100%;
                border-bottom: 1px solid #d2d2d2;
                color: $defaultColor;
                background-color: white;
                tr {
                    font-weight: 500;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #d2d2d2;
                    display: block;

                    td {
                        width: 100%;
                        display: block;
                        p {
                            margin: 10px 0;
                            display: flex;
                            justify-content: space-between;

                            .accounts__label, .accounts__value, .accounts__Info {
                                font-size: 14px;
                            }

                            .accounts__Info {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .accounts__changepassword {
            border: 1px solid #d2d2d2;
            border-radius: 8px;
            background-color: #fff;
            height: max-content;
            h5 {
                font-size: 16px;
                color: $defaultColor;
                background-color: white;
                font-weight: 500;
                display: flex;
                align-items: center;
                height: 45px;
                padding-left: 1em;
            }
            .accounts__changepassword__form {
                display: flex;
                flex-direction: column;
                padding: 10px;
                input {
                    border: 1px solid #d2d2d2;
                    font-size: 14px;
                    width: 100%;
                    margin: 1em 0;
                    height: 36px;
                    padding-left: 10px;

                    &:focus {
                        outline: none;
                    }
                }

                button {
                    background-color: #000;
                    color: white;
                    padding: 10px 2em;
                    font-size: 14px;
                    max-width: 50%;
                    margin: 0 auto;
                }

                .error {
                    color: red;
                }
                .success {
                    color: #198754;
                }
            }
        }
    }
}

@media (max-width: 920px) {
    .accounts {
        .accounts__container {
            flex-direction: column;
            gap: 1em;
            & > * {
                width: 100%;
            }
        }
    }
}

@media(max-width: 580px) {
    .accounts {
        table {
            font-size: 0.8em;
          }
        
          th, td {
            display: block;
            width: 100%;
          }
        
          th {
            text-align: left;
          }
        
          td[data-label]:before {
            display: inline-block;
            margin-right: 0.5em;
          }
        .accounts__container {

            .accounts__info {
                thead {
                    tr {
                        position: unset;
                    }
                }
                tbody {
                    tr {
                        td {
                            width: 95%;
                            margin: 0 auto;
                        }
                    }
                }
            }

            .accounts__myaddress {
                .add__address {
                    max-width: 100%;
                }
            }
        }
    }
}