.BrandSupplier__section {
    background-color: #EBEBEB;
    padding: 3rem 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 7px;

    .BrandSupplier__block {
        max-width: 1680px;
        width: 100%;
        margin: auto;
        padding: 0 15px;

        .BrandSupplier__content {
            text-align: center;
            max-width: 875px;
            margin: auto;
            // margin-bottom: 4em;

            .BrandSupplier__title {
                letter-spacing: 0px;
                color: #0DA5E8;
                text-transform: uppercase;
                font-size: 32px;
                line-height: 40px;
                font-weight: 600;
                width: 90%;
                margin: auto;
            }

            .Licensed__text {
                width: 80%;
                margin: auto;
                margin-bottom: 3rem;
            }

            .BrandSupplier__text {
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
                letter-spacing: 0px;
                color: #2b2b2b;
            }
        }

        .ps-list {
            padding: 0 0 0 20px;
            margin: 0;

            li {
                list-style: none;

                a {
                    font-size: 16px;
                    color: #2b2b2b;
                    line-height: 38px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .BrandSupplier__section {
        padding: 3rem 15px;

        .BrandSupplier__block {
            .BrandSupplier__content {
                .BrandSupplier__title {
                    font-size: 28px;
                    line-height: 36px;
                }

                .BrandSupplier__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }

        .BrandSupplier__block .ps-list li {
            padding-bottom: 10px;

            a {
                line-height: 24px;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .BrandSupplier__section {
        .BrandSupplier__block {
            .BrandSupplier__content {
                .BrandSupplier__title {
                    font-size: 24px;
                    line-height: 32px;
                }

                .BrandSupplier__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .BrandSupplier__section {
        .BrandSupplier__block {
            .BrandSupplier__content {
                .BrandSupplier__title {
                    font-size: 24px;
                    line-height: 32px;
                }

                .BrandSupplier__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .BrandSupplier__section {
        .BrandSupplier__block {
            .BrandSupplier__content {
                margin-bottom: 2rem;

                .BrandSupplier__title {
                    font-size: 24px;
                    line-height: 32px;
                }

                .BrandSupplier__text {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            .BrandSupplier__link__text {
                .row div {
                    margin-bottom: 15px;
                }
            }
        }
    }
}