.Regdis__section {
    position: relative;
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 7px 7px inset;
    background: #e2e2e2 url("../../../assects/images/home/background__1.png") right bottom no-repeat;

    .Regdis__container {
        max-width: 80%;
        width: 100%;
        margin: auto;
        padding: 5em 0;

        .Regdis__row {
            display: flex;
            justify-content: space-between;

            .Regdis__advantage {
                width: 55%;

                .randd-cont-heading {
                    letter-spacing: 0px;
                    color: #0DA5E8;
                    font-size: 26px;
                    line-height: 34px;
                    font-weight: 600;
                    margin-bottom: 35px;
                }

                .randd-cont-services {
                    margin: 0px;
                    padding: 0px;
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        color: #2b2b2b;
                        float: left;
                        font-size: 20px;
                        list-style-type: none;
                        margin-bottom: 40px;
                        padding-right: 80px;
                        width: 50%;
                        line-height: 2rem;

                        img {
                            margin-right: 15px;
                            width: 75px;
                            opacity: 0.9;
                            height: auto;
                        }
                    }
                }
            }

            .Regdis__identifier {
                width: 35%;

                .identifier__block {
                    width: 100%;
                    background: url('../../../assects/images/home/skyblue-bg.png') repeat;
                    padding: 7%;
                    display: block;

                    .mt-4 {
                        margin-top: 0 !important;
                    }


                    .dos-heading {
                        font-size: 22px;
                        line-height: 30px;
                        color: #2b2b2b;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    .dos-subheading {
                        font-size: 22px;
                        color: #fff;
                        font-weight: bold;
                        line-height: 30px;
                        margin-bottom: 20px;
                    }

                    .dos {
                        margin: 0px;
                        overflow: hidden;
                        padding: 0px;

                        li {
                            background-color: #fff;
                            font-weight: 600;
                            list-style-type: none;
                            margin: 0px 0px 30px 0px;
                            padding: 15px 1px 15px 15px;
                            width: 100%;
                            line-height: 2rem;

                            a {
                                text-decoration: none;
                                color: #2b2b2b;
                                display: flex;
                                align-items: center;
                                font-size: 20px;
                                line-height: 28px;

                                img {
                                    width: 50px;
                                    height: 50px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}

@media only screen and (max-width: 1680px) {
    .Regdis__section {
        .Regdis__container {
            max-width: 90%;
        }
    }
}

@media only screen and (max-width: 1366px) {
    .Regdis__section {
        .Regdis__container {
            .Regdis__row {
                .Regdis__advantage {
                    .randd-cont-heading {
                        font-size: 24px;
                        line-height: 32px;
                    }

                    .randd-cont-services {
                        li {
                            font-size: 18px;
                            line-height: 26px;

                            img {
                                width: 65px;
                            }
                        }
                    }
                }

                .Regdis__identifier {
                    .identifier__block {
                        .dos-heading {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        .dos-subheading {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        .dos {
                            li {
                                a {
                                    font-size: 18px;
                                    line-height: 26px;

                                    img {
                                        width: 45px;
                                        height: 45px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .Regdis__section {
        .Regdis__container {
            .Regdis__row {
                .Regdis__advantage {
                    width: 62%;

                    .randd-cont-heading {
                        font-size: 22px;
                        line-height: 30px;
                    }

                    .randd-cont-services {
                        li {
                            font-size: 16px;
                            line-height: 24px;
                            padding-right: 50px;

                            img {
                                width: 65px;
                            }
                        }
                    }
                }

                .Regdis__identifier {
                    width: 38%;

                    .identifier__block {
                        .dos-heading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos-subheading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos {
                            li {

                                a {
                                    font-size: 16px;
                                    line-height: 24px;

                                    img {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .Regdis__section {
        .Regdis__container {
            max-width: 95%;
        }
    }
}


@media only screen and (max-width: 768px) {
    .Regdis__section {
        .Regdis__container {
            .Regdis__row {
                flex-wrap: wrap;

                .Regdis__advantage {
                    width: 100%;
                }

                .Regdis__identifier {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .Regdis__section {
        .Regdis__container {
            .Regdis__row {
                .Regdis__advantage {
                    width: 100%;

                    .randd-cont-heading {
                        font-size: 22px;
                        line-height: 30px;
                    }

                    .randd-cont-services {
                        li {
                            padding-right: 30px;

                            img {
                                width: 55px;
                            }
                        }
                    }
                }

                .Regdis__identifier {
                    width: 100%;

                    .identifier__block {
                        .dos-heading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos-subheading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos {
                            li {

                                a {
                                    font-size: 16px;
                                    line-height: 24px;

                                    img {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .Regdis__section {
        .Regdis__container {
            padding: 2rem 0;

            .Regdis__row {
                .Regdis__advantage {
                    .randd-cont-heading {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .randd-cont-services {
                        li {
                            width: 100%;

                            img {
                                width: 45px;
                            }
                        }
                    }
                }
            }
        }
    }
}