.contactUs__section {
    max-width: 1680px;
    width: 100%;
    padding: 0 15px;
    margin: auto;

    .contactUs__block {
        display: flex;
        justify-content: space-between;
        padding: 3rem 0;

        .contactUs__content {
            width: 43%;
            .container {
                padding: 0;

                > div {
                    padding: 0px !important;
                    width: 60%;
                    margin: auto;
                }

                h1 {
                    color: #0da6e9;
                    font-size: 24px;
                    font-weight: 500;
                    margin-bottom: 20px;
                }

                p {
                    color: #686868;
                    font-size: 16px;
                }
            }
        }

        .contactUs__form {
            width: 50%;
            margin-top: 12px;

            .fieldset {
                border: 1px solid #ced4da;
                padding: 20px;
                border-radius: 10px;
                position: relative;
                background-color: #F8F9FA;
                box-shadow: 8px 6px rgba(39, 84, 170, 0.1607843137);
                min-height: 434px;

                .row > .alert{
                    width: calc(100% - 30px);
                    margin-left: auto;
                    margin-right: auto;
                }

                h2 {
                    font-size: 24px;
                    display: inline-block;
                    background-color: #FFF;
                    margin-top: -33px;
                    position: absolute;
                    padding: 0 10px;
                    color: #0da6e9;
                }

                .form-label {
                    font-size: 16px;
                    color: #2b2b2b;
                    margin: 0;

                    .rf{
                        color: red;
                    }
                }

                .register-error {
                    color: red;
                    font-size: 14px;
                }

                input {
                    margin: 10px 0;
                    height: 44px;
                    width: 100%;
                    padding-left: 10px;
                    border: 1px solid #ced4da;
                }

                textarea {
                    margin: 10px 0;
                    width: 100%;
                    padding-left: 10px;
                    border: 1px solid #ced4da;
                }

                .formSubmit {
                    text-align: right;
                    .submit__btn {
                        background-color: #000;
                        color: #FFF;
                        font-size: 18px;
                        border: none;
                        outline: none;
                        padding: 10px 30px;
                        border-radius: 7px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width:1680px) {
    .contactUs__section {
        width: 80%;
    }
}

@media only screen and (max-width:1450px) {
    .contactUs__section {
        width: 98%;
        padding: 0px 15px;
        .contactUs__block{
            .contactUs__form{
                width: 57%;
            }
            .contactUs__content{
                width: 100%;
                max-width: 500px;
                .container > div{
                    width: 370px;
                    margin-left: 0px;
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .contactUs__section {
        .contactUs__block{
            .contactUs__form{
                width: 65%;
            }
            .contactUs__content{
                max-width: 400px;
            }
        }
    }
}

@media only screen and (max-width:992px) {
    .contactUs__section {
        width: 100%;
        padding: 0px 15px;
        .contactUs__block{
            padding: 1.5em 0px;
            flex-direction: column-reverse;
            align-items: center;
            .contactUs__form{
                margin-top: 55px;
                width: 80%;
            }
            .contactUs__content{
                max-width: inherit;
                width: 80%;
                .container > div{
                    width: 100%;
                }
                .container{
                    max-width: 100%;
                    p:first-child{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:768px) {
    .contactUs__section {
        .contactUs__block{
            .contactUs__form{
                width: 90%;
                form > .fieldset {
                    h2{
                        font-size: 20px;
                        margin-top: -32px;
                    }
                    .row{
                        .col-6{
                            width: 100%;
                            margin-bottom: 0px !important;
                            margin-top: 1em !important;
                        }
                        .col-12{
                            margin-top: 1em;
                        }
                        .formSubmit{
                            text-align: left;
                        }
                    }
                }
            }
            .contactUs__content{
                width: 90%;
            }
        }
    }
}
@media only screen and (max-width:480px) {
    .contactUs__section {
        .contactUs__block{
            .contactUs__form{
                width: 98%;
                form > .fieldset {
                    box-shadow: unset;
                    h2{
                        font-size: 16px;
                        margin-top: -29px;
                    }
                    .row *{
                        font-size: 14px;
                    }
                }
            }
            .contactUs__content{
                width: 98%;
                .container{
                    h1{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:375px) {
    .contactUs__section {
        .contactUs__block{
            .contactUs__form{
                form > .fieldset {
                    padding: 10px;
                    h2{
                        font-size: 14px;
                        margin-top: -18px;
                    }
                    .row *{
                        font-size: 14px;
                    }
                    .row{
                        div:nth-last-child(2) div{
                            width: 100% !important;
                            iframe{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}