.dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    border: none;
    padding: 0;

    .dropdown-item {
        padding: 10px 20px;
        text-transform: uppercase;

        &:hover {
            border-radius: 6px;
        }

        &:active {
            background-color: #dedada;
        }

        img {
            margin-right: 10px !important;
        }
    }
}

