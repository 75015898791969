body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* vietnamese */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assects/Lexend/static/Lexend-Regular.ttf') format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./assects/Lexend/static/Lexend-Medium.ttf') format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./assects/Lexend/static/Lexend-SemiBold.ttf') format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assects/Lexend/static/Lexend-Bold.ttf') format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./assects/Lexend/static/Lexend-ExtraBold.ttf') format('woff2');
}

/* vietnamese */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('./assects/Lexend/static/Lexend-Black.ttf') format('woff2');
}