$defaultColor: #0da5e8;
/* The container for the progress bar */
.progressbar {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    max-width: 1024px;
    margin: 0 auto;
  }
  
  /* Individual steps */
  .step-progress__step {
    flex-basis: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* The line between steps */
  .step-progress__line {
    position: absolute;
    top: 0%;
    height: 2px;
    background-color: #FFF;
    width: 100%;
  }

  .lastStep {
    width: 50%;
    left: 0;
  }
  .firstStep {
    width: 50%;
    right: 0;
  }
  
  /* The line between the active step and the next step */
  .step-progress__line.active {
    background-color: $defaultColor;
  }
  
  /* The step number */
  .step-progress__number {
    position: absolute;
    top: -20px;
    font-size: 20px;
    color: #333;
    background-color: #fff;
    color: $defaultColor;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #FFF;
    cursor: pointer;
  }
  
  /* The active step number */
  .step-progress__number.active {
    border-color: $defaultColor;
    background-color: $defaultColor;
    color: #fff;
  }
  /* The active step number */
  .step-progress__number.inactive {
    pointer-events: none
  }
  
  /* The step title */
  .step-progress__title {
    margin-top: 3em;
    font-size: 14px;
    color: #333;
    text-align: center;
  }
  

  @media(max-width: 480px) {
    .step-progress__title {
        font-size: 10px;
    }
  }