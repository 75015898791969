.BrandBlockWrapper {
    display: flex;
    align-items: center;
    column-gap: 50px;
    justify-content: center;

    .bannerimg {
        width: 355px;
        height: 355px;
    }

    .contentWrapper {
        width: 30%;

        p {
            font-weight: 500;
            text-align: center;
        }

        .contentWrapperTitle {
            padding-bottom: 1rem;
        }
    }
}

@media only screen and (max-width:992px) {
    .BrandBlockWrapper {
        flex-direction: column;
        row-gap: 20px;

        .contentWrapper {
            width: 75%;
        }

        .bannerimg {
            width: 255px;
            height: 255px;
        }
    }
}