.EmptyCart__Section {
    .EmptyCart_Title h1 {
        font-size: 32px;
        color: #0da5e8;
        font-weight: 500;
        letter-spacing: -1px;
        margin-bottom: 20px;
    }

    .EmptyCart__warp {
        p {
            color: #2b2b2b;
        }

        .EmptyCart__action {
            display: flex;
            margin-top: 20px;

            .EmptyCart__homelink {
                font-size: 18px;
                color: #FFF;
                background-color: #000;
                text-decoration: none;
                padding: 12px 35px;
                border-radius: 4px;
            }
        }
    }
}