$defaultColor: #0da5e8;
.loader {
    position: fixed;
    max-width: 100vw;
    height: 100vh;
    background-color: gray;
    top: 0;
    z-index: 9999;
    opacity: .2;
}
.loader__container {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .item {
    width: 60px;
    height: 60px;
    position: absolute;

    img {
        opacity: 1;
        width: 100%;
        height: 100%;
        stroke: transparent;
        stroke-width: 0px;
        fill: #fff;
    }
  }
  
  .item-1 {
    background-color: #FA5667;
    // background-color: #2b2b2b;
    top: 0;
    left: 0;
    z-index: 1;
    animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  .item-2 {
    background-color: #7A45E5;
    // background-color: $defaultColor;
    top: 0;
    right: 0;
    animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  .item-3 {
    background-color: #1B91F7;
    // background-color: #2b2b2b;
    bottom: 0;
    right: 0;
    z-index: 1;
    animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  .item-4 {
    background-color: #FAC24C;
    // background-color: $defaultColor;
    bottom: 0;
    left: 0;
    animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  @keyframes item-1_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(0, 100px)} 
    50% {transform: translate(100px, 100px)} 
    75% {transform: translate(100px, 0)} 
  }
  
  @keyframes item-2_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(-100px, 0)} 
    50% {transform: translate(-100px, 100px)} 
    75% {transform: translate(0, 100px)} 
  }
  
  @keyframes item-3_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(0, -100px)} 
    50% {transform: translate(-100px, -100px)} 
    75% {transform: translate(-100px, 0)} 
  }
  
  @keyframes item-4_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(100px, 0)} 
    50% {transform: translate(100px, -100px)} 
    75% {transform: translate(0, -100px)} 
  }