$defaultColor: #0da6e9;
.b2cregister {
  min-height: 60vh;
  max-width: 1920px;
  margin: 0 auto;
  
  .b2cregister__container {
    max-width: 1680px;
    width: 100%;
    margin: auto;
    padding: 3em 0;
    
    .b2cregister__block {
      padding: 3em 1em;
      border: 1px solid #2b2b2b;
      max-width: 35%;
      margin:auto;
      h3 {
        color: #0da6e9;
        font-size: 16px;
        text-align: center;
      }
      p {
        font-size: 16px;
        text-align: center;
      }
      .b2cregister__validations {
        display: flex;
        color: red;
        justify-content: flex-end;
        margin: 1em 0;
      }
    }

    .b2cregister__form {
      display: flex;
      flex-direction: column;
      // margin-top: 3em;

      & > input {
        margin-bottom: 1em;
        height: 36px;
        // color: #f5f5f5;
        padding-left: 10px;
      }

      label {
          display: flex;
          margin-bottom: 8px;

          a {
            margin: 0;
            cursor: pointer;
            color: #2b2b2b;;
            &:hover {
              color: $defaultColor;
            }
          }

          input {
          margin: 0;
          margin-right: 10px;
        }
      }
      .b2cregister__create {
        width: max-content;
        margin: 1em auto;
        background-color: #000;
        color: white;
        padding: 10px 3em;
      }

      .b2cregister__error {
        color: red;
        text-align: left;
        padding-left: 10px;
      }
    }
  }
}

@media(max-width: 820px) {
  .b2cregister {
    .b2cregister__container {
      .b2cregister__block {
        max-width: 50%;
      }
    }
  }
}

@media(max-width: 580px) {
  .b2cregister {
    .b2cregister__container {
      .b2cregister__block {
        max-width: 90%;
      }
    }
  }
}