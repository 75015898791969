.Character__section {
    .Character__content {
        text-align: center;
        max-width: 1200px;
        margin: 4rem auto 3em;

        .Character__title {
            letter-spacing: 0px;
            color: #0DA5E8;
            text-transform: uppercase;
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            margin-bottom: 10px;
        }

        .Character__text {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 26px;
            color: #2b2b2b;
        }
    }

    .Character__slider {
        overflow: hidden;

        .slick-slider {

            .slick-prev,
            .slick-next {
                top: 45%;
            }

            .slick-list {
                overflow: initial;
            }
        }


        .Character__slider__item {
            max-width: 190px;
            margin: auto;

            .Slider__image {
                box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
                box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

                img {
                    width: 100%;
                    // height: auto;
                }
            }
        }

        .Slider__content {
            min-height: 80px;
            max-height: 80px;
            text-overflow: ellipsis;
            cursor: pointer;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding: 25px 10px 0;
            margin-bottom: 25px;

            .Character__title {
                letter-spacing: 0px;
                color: #757575;
                font-size: 18px;
                line-height: 26px;
                font-weight: 600;
            }
        }
    }
}

@media only screen and (max-width: 1680px) {
    .Character__section .Character__slider .Slider__content {
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 1366px) {
    .Character__section {
        .Character__content {
            .Character__title {
                font-size: 28px;
                line-height: 36px;
            }

            .Character__text {
                font-size: 16px;
                line-height: 26px;
            }
        }

        .Character__slider {
            .Slider__content {
                .Character__title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .Character__section {
        .Character__content {
            .Character__title {
                font-size: 24px;
                line-height: 32px;
            }

            .Character__text {
                font-size: 16px;
                line-height: 26px;
            }
        }

    }
}

@media only screen and (max-width: 600px) {
    .Character__section {
        .Character__content {
            margin: 2rem auto;

            .Character__title {
                font-size: 22px;
            }
        }

        .Licensed__gallery {
            .Gallery__block {
                .Gallery__content {
                    .Gallery__title {
                        font-size: 22px;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 485px) {
    .Character__section {
        .Character__content {
            margin: 2rem auto;

            .Character__title {
                font-size: 22px;
            }
        }

        .Character__slider {
            .slick-slider {
                width: 100% !important;
            }

            .Character__slider__item .Slider__image {
                width: 90%;
                margin: auto;
                min-height: 472.5px;
            }
        }
    }
}