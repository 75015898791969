.Minicart__block {
    .Minicart__Icon {
        position: relative;
        cursor: pointer;
        margin-right: 10px;

        svg {
            color: #FFF;
            width: 17px;
            height: 17px;
            display: block;
        }

        .base__count {
            position: absolute;
            color: #FFF;
            background: #0da5e8;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            right: -18px;
            top: -4px;
        }
    }

}

.miniCart__Nav__section {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    width: 25vw;
    right: -40vw;
    transition: left 1s;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,rgba(0, 0, 0, 0.05) 0px 5px 10px;
    transition: left 1s;

    &.miniCart__open {
        position: fixed;
        opacity: 1;
        right: 0;
        z-index: 99;
        transition: left 1s;
    }
}

.miniCart__Nav__section {
    position: fixed;
    z-index: 102;
    top: 0;
    right: -40vw;
    transition: right 1s;

    &.miniCart__open {
        position: fixed;
        opacity: 1;
        right: 0;
        z-index: 102;
        transition: right 1s;
    }

    .miniCart__popup {
        position: relative;
        
        width: 25vw;
        background-color: white;
        height: 100vh;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,rgba(0, 0, 0, 0.05) 0px 5px 10px;

        .miniCart__header {
            display: flex;
            padding: 15px 20px;
            justify-content: space-between;
            align-items: center;
            background-color: #000;

            .minicart__header__title {
                font-size: 18px;
                text-transform: capitalize;
                color: #FFF;
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .button__close {
                background-color: transparent;
                border: none;
                color: white;
                display: flex;
                align-items: center;
                padding: 0;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .miniCart__body {
            height: calc(100% - 215px);
            overflow-y: auto;

            .miniCart__Product__Block {
                display: flex;
                justify-content: space-between;
                padding: 15px;
                border-bottom: 1px solid #0000001a;
                align-items: center;

                .miniCart__Product__Image {
                    width: 25%;
                    padding: 5px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .miniCart__Product__Details {
                    width: 68%;

                    .miniCart__Product__row {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        align-items: center;

                        span.miniCart__Product__Name {
                            color: #2b2b2b;
                            font-size: 16px;
                            cursor: pointer;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            max-height: 48px;
                        }

                        .miniCart__Product__Qty {
                            color: #2b2b2b;
                            font-size: 16px;
                        }

                        .miniCart__Product__Price {
                            color: #0da5e8;
                            font-size: 18px;
                        }

                        .miniCart__Product__Action {
                            cursor: pointer;
                            svg {
                                width: 22px;
                                height: 22px;
                                color: #2b2b2b;
                            }
                        }
                    }

                }
            }
        }

        .miniCart__footer {
            margin-top: 15px;
            box-shadow: rgba(0, 0, 0, 0.1490196078) 0px -3.05px 5.6px;
            padding: 20px 15px;
            bottom: 0;
            position: absolute;
            width: 100%;

            .miniCart__footer__row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;

                .miniCart__footer__text {
                    color: #2b2b2b;
                    font-size: 18px;
                    font-weight: 600;
                }

                .miniCart__footer__price {
                    color: #2b2b2b;
                    font-size: 18px;
                    font-weight: 600;
                }

                .miniCart__btn {
                    width: 100%;
                    padding: 12px 10px;
                    border: none;
                    outline: none;
                    background-color: #000;
                    border-radius: 4px;
                    color: #FFF;
                    font-weight: 600;
                    letter-spacing: 1px;

                    &:hover {
                        background-color: #0da5e8;
                    }
                }
            }
        }

        .currency {
            margin-left: 5px;
        }

        .miniCart__empty {
            padding: 30px 0;
            font-size: 22px;
        }
    }
}

.miniCart__Navback__section {
    display: none;
    &.miniCart__open {
        display: block;
        position: fixed;
        opacity: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        z-index: 98;
        transition: left 1s;
        background-color: gray;
    }
}

@media only screen and (max-width: 1450px) {
    .miniCart__Nav__section {
        width: 30vw;
        right: -40vw;

        .miniCart__popup {
            width: 30vw;
        }
    }
}

@media only screen and (max-width: 1366px) {
    .miniCart__Nav__section {
        width: 35vw;
        right: -40vw;

        .miniCart__popup {
            width: 35vw;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .miniCart__Nav__section {
        width: 40vw;
        right: -45vw;

        .miniCart__popup {
            width: 40vw;
        }
    }
}

@media only screen and (max-width: 992px) {
    .miniCart__Nav__section {
        width: 45vw;
        right: -50vw;
        .miniCart__popup {
            width: 45vw;
        }
        
    }
}

@media only screen and (max-width: 750px) {
    .miniCart__Nav__section {
        width: 60vw;
        right: -65vw;
        .miniCart__popup {
            width: 60vw;
        }
    }
}

@media only screen and (max-width: 600px) {
    .miniCart__Nav__section {
        width: 70vw;
        right: -75vw;
        .miniCart__popup {
            width: 70vw;
        }
    }
}


@media only screen and (max-width: 550px) {
    .miniCart__Nav__section {
        width: 80vw;
        right: -85vw;
        .miniCart__popup {
            width: 80vw;
        }
    }
}

@media only screen and (max-width: 450px) {
    .miniCart__Nav__section {
        width: 85vw;
        right: -90vw;
        .miniCart__popup {
            width: 85vw;
        }
    }
}
