.DoubleBannerWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .ImageWrapper {
        position: relative;
        width: 50%;

        .sideBannerImage {
            width: 100%;
            height: 100%;
        }

        .Gallery__title {
            position: absolute;
            bottom: 20px;
            left: 30px;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            background-color: #0da5e8;
            padding: 5px 15px;
            // text-align: center;
        }
    }
}

@media only screen and (max-width:992px) {
    .DoubleBannerWrapper .ImageWrapper .Gallery__title {
        font-size: 12px !important;
        padding: 0px 10px;
    }
}