.ProductList__block{
    box-shadow: 0 2px 8px 0 rgba(99,99,99,.3);
    position: relative;
    overflow: hidden;
    padding: 10px;
    margin: 5px 0;
    width: 100%;
    display: flex;

    .ProductList__lable {
        .pre__sale {
            width: 100%;
            display: block;
            position: absolute;
            background-color: #007042;
            color: #FFF;
            text-transform: uppercase;
            letter-spacing: 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            transform: rotate(-45deg);
            top: 12%;
            left: -34%;
            z-index: 1;
            justify-content: center;
        }

        .offer__label {
            width: 100%;
            display: block;
            position: absolute;
            background-color: #eb004b;
            color: #FFF;
            text-transform: uppercase;
            letter-spacing: 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            transform: rotate(45deg);
            top: 12%;
            right: -34%;
            z-index: 1;
            justify-content: center;
        }
    }

    .ProductList__img__block {
        padding: 15px;
        position: relative;
        overflow: hidden;
        max-width: 270px;

        .ProductList__image {
            width: 100%;
            transition: all 0.5s ease;
        }
    }

    .ProductList__desc__block {
        padding: 10px 10px 10px 35px;
        width: calc(100% - 270px );

        .ProductList__title__block {
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 5px;
            position: relative;
            display: flex;

            .ProductList__sku {
                font-size: 18px;
                font-weight: 600;
            }

            .ProductList__name {
                margin: 0 15px;
            }

            .ProductList__brand {
                color: #0da6ea;
                font-weight: 600;
            }

            &::after {
                content: '';
                background: #0da6ea;
                height: 1px;
                left: 0;
                position: absolute;
                width: 95px;
                bottom: 0;
            }
        }

        .ProductList__description__block {
            margin: 15px 0;
            width: 90%;
        }

        .ProductList__cart__block {
            transform: translateY(0px);
            transition: 0.5s all;
            background-color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 30%;
            margin: 10px 0;
    
            .cart__action {
                width: 76%;
    
                .btn__addtocart {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #FFF;
                    width: 100%;
    
                    .cart__icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 7px;
                    }
                }
            }
    
            .cart__input__block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 40%;
    
                .btn__decrease,
                .btn__increase {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 26%;
                    min-height: 38px;
    
                    .inde__icon {
                        color: #FFFFFF;
                        width: 20px;
                        height: 20px;
                    }
                }
    
                .cart__input {
                    width: 48%;
                    height: 38px;
                    text-align: center;
                    border-left: none;
                    border-right: none;
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                }
            }
    
            .cart__btn {
                border: none;
                background-color: transparent;
                outline: none;
                box-shadow: none;
                padding: 7px;
    
                &:hover {
                    background-color: #0da5e8;
                }
            }
        }

        .ProductList__price__block {
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 5px;
            position: relative;
            margin: 15px 0px;

            display: flex;

            .ProductList__price {
                font-size: 36px;
                font-weight: 500;
            }

            .ProductList__discount {
                font-size: 20px;
                color: red;
                text-decoration: line-through;
                margin: 0 15px;
            }

            .distributor__row {
                display: flex;
                align-items: center;

                .distributor__start {
                    display: block;
                    border: 1px solid #F4F4F4;
                    height: 100%;
                    margin-right: 15px;
                }

                .distributor__price {
                    margin-right: 15px;
                }
            }

            &::after {
                content: '';
                background: #0da6ea;
                height: 1px;
                left: 0;
                position: absolute;
                width: 95px;
                bottom: 0;
            }

        }
    }

    // &:hover {
    //     .ProductList__img__block {   
    //         .ProductList__image {
    //             transform: scale(1.2);
    //         }
    //     }
    // }
}

@media only screen and (max-width: 1600px) {
    .ProductList__block {
        .ProductList__desc__block {
            .ProductList__cart__block {
                width: 45%;
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .ProductList__block {
        .ProductList__desc__block {
            .ProductList__description__block {
                text-overflow: ellipsis;
                cursor: pointer;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }

            .ProductList__cart__block {
                width: 50%;
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .ProductList__block {
        .ProductList__desc__block {
            .ProductList__price__block {
                .ProductList__price {
                    font-size: 28px;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .ProductList__block {
        .ProductList__desc__block {
            .ProductList__cart__block {
                width: 60%;
            }

            .ProductList__description__block {
                -webkit-line-clamp: 3;
            }

            .ProductList__price__block {
                .ProductList__price {
                    font-size: 24px;
                }
            }
        }
    }
}

@media(max-width: 920px) {
    .ProductList__block {
        .ProductList__cart__block {
            width: 60%;
        }

        .ProductList__desc__block {
            .ProductList__title__block {
                flex-wrap: wrap;

                span:nth-of-type(1) {
                    margin-right: 10px;
                }

                span:nth-of-type(3) {
                    flex-basis: 100%;
                }

                  .ProductList__name {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    .ProductList__block {
        .ProductList__desc__block {
            .ProductList__cart__block {
                width: 70%;
            }

            .ProductList__description__block {
                -webkit-line-clamp: 3;
            }

            .ProductList__price__block {
                flex-wrap: wrap;

                .ProductList__row {
                    width: 100%;
                }

                .distributor__row {
                    .distributor__start {
                        display: none !important;
                    }
                }

                .ProductList__price {
                    font-size: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ProductList__block {
        .ProductList__desc__block {
            .ProductList__cart__block {
                width: 80%;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .ProductList__block {
        .ProductList__desc__block {
            .ProductList__cart__block {
                width: 100%;
            }

            .ProductList__price__block {
                .distributor__row {
                    flex-wrap: wrap;

                    .distributor__price {
                        width: 100%;
                        margin-top: 10px;
                    }
                }

                .ProductList__price {
                    font-size: 20px;
                }
            }
        }
    }
}



@media(max-width: 620px) {
    .ProductList__block {
        flex-wrap: wrap;

        .ProductList__img__block, .ProductList__desc__block {
            padding: 0;
            width: 100%;
        }

        .ProductList__desc__block {
            .ProductList__title__block {
                flex-direction: column;
            }
        }

        .ProductList__cart__block {
            width: 100% !important;
        }
    }
}

@media(max-width: 480px) {
    .ProductList__block .ProductList__img__block {
        max-width: 100%;
        margin-bottom: 20px;
    }
}

