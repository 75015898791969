.SuccessPage__Block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 25px;

    .SuccessPage__Img {
        svg {
            width: 70px;
            height: 70px;
            display: block;
            color: #0da5e8;
        }
    }

    .SuccessPage__title {
        color: #0da5e8;
        font-size: 32px;
        text-transform: uppercase;
    }

    .SuccessPage__info,
    .SuccessPage__orderNo {
        color: #2b2b2b;
        font-size: 18px;

        .SuccessPage__orderinfo {
            font-weight: 600;
        }
    }

    .SuccessPage__nav {
        display: block;
        margin-top: 25px;

        .EmptyCart__homelink {
            font-size: 18px;
            color: #FFF;
            background-color: #000;
            text-decoration: none;
            padding: 12px 35px;
            border-radius: 4px;

            &:hover {
                background-color: #0da5e8;
            }
        }
    }

    

}