$defaultColor: #0da6e9;
.shopregister {
    min-height: 80vh;

    .shopregister__container {
        max-width: 1680px;
        width: 100%;
        margin: auto;
        padding: 3em 15px;

        .shopregister__block {
            display: flex;
            gap: 3em;

            .shopregister__formContainer {
                padding: 10px;
                background-color: #F8F9FA;
                border: 1px solid lightgray;
                border-radius: 10px;

                h3 {
                    font-size: 20px;
                    color: #0da6e9;
                    text-align: center;
                }
                p {
                    font-size: 16px;
                }
                .shopregister__form {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    .agree_terms {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        
                        input {
                            margin: 0px 10px 0 0;
                        }
                    }

                    .error {
                        text-align: left;
                        padding-left: 10px;
                        color: red;
                    }

                    & > input, select {
                        height: 36px;
                        padding-left: 10px;
                        margin-bottom: 1em;
                        font-size: 14px;
                    }

                    label {
                        display: flex;
                        margin-bottom: 8px;

                        a {
                            margin: 0;
                            cursor: pointer;
                            color: #2b2b2b;;
                            &:hover {
                                color: $defaultColor;
                            }
                        }

                        input {
                            margin-right: 10px;
                            margin-bottom: 1em;
                        }
                    }

                    .shopregister__submit {
                        background-color: #000;
                        padding: 10px 3em;
                        margin: 1em auto 0;
                        color: white;
                    }
                }
            }

            .shopregister__info {
                h2 {
                    color: #0da6e9;
                }
            }
        }
    }
}

@media (max-width: 812px) {
    .shopregister__block {
        flex-direction: column-reverse;
    }
}