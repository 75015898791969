$defaultColor: #0da5e8;
.checkout__progressbar {
    padding: 70px 0 60px;
    background-color: #dedede;
}

.checkout {
    max-width: 1680px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px 2%;

    .checkout__content {
        margin-top: 2em;
        display: flex;
        justify-content: space-between;

        & > .BillingAddressInfo {
            width: 75%;
        }

        & > .OrderSummery__warp {
            width: 100%;
        }
        
        & > .CartSummary {
            width: 23%;
        }

        & > .CartListing__warp {
            width: 100%;
        }
        & > .register {
            width: 100%;
            margin: 0 !important;
                .register__container {
                    padding: 0;
                    max-width: 1440px;
                    margin: 0 auto;

                    .register__form {
                        min-height: unset;
                    }

                    .B2C__title, .B2C__description, .B2B__title, .B2B__description {
                        display: none;
                    }
                .register__content {
                    display: block;
                    width: 50%;

                    .content__B2C, .content__B2B {
                        width: unset;
                        display: block;
                        min-height: unset;
                    }

                    .content__B2C {
                        display: flex;
                        flex-direction: row-reverse;
                        .Continue__guest {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;    
                            background-color: #f1f1f1;
                            color: $defaultColor;
                            flex-direction: column;
                            min-height: 10em;
                            padding: 10px;
                            cursor: pointer;
                            text-align: center;
                            text-transform: uppercase;

                            &:hover {
                                background-color: $defaultColor;
                                color: white;
                            }
                        }
                        & > * {
                            width: calc(100% / 2 - 10px);
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1024px) {
    .checkout {
        .checkout__content {
            flex-direction: column;
            .BillingAddressInfo {
                width: 100%;
            }
            .CartSummary {
                width: 100%;
            }
            & > .register {
                .register__container {
                    padding: 0;
                    .register__content {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media(max-width: 420px) {
    .checkout {
        margin-bottom: 3em;
        .checkout__content {
            flex-direction: column;
            & > .register {
                .register__container {
                    gap: 0;
                    .register__content {
                        .content__B2C {
                            flex-direction: column-reverse;
                            gap: 1em;
                            & > * {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}