.ProductListSkeleton {
    display: flex;
    width: 100%;

    & > * {
        padding: 15px;
    }

    .ProductList__desc__block1 {
        flex: 1;

        .ProductList__sku1 {
            display: block !important;

            & > span {
                display: block !important;
            }
        }

        .ProductList__title__block1, .ProductList__description__block1 {
            margin-bottom: 1em;
            & * {
                min-width: 320px;
                width: 100%;
            }
        }
    }
}