.placeholderImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 270px;
    background-color: #ebebeb;

    .placeholderImage__img {
        opacity: 0.2;
    }
}