.ProductCat__section {
    .ProductCat__content {
        text-align: center;
        max-width: 1200px;
        margin: auto;

        .ProductCat__title {
            letter-spacing: 0px;
            color: #0DA5E8;
            text-transform: uppercase;
            font-size: 32px;
            line-height: 40px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .ProductCat__text {
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
            letter-spacing: 0px;
            color: #2b2b2b;
        }
    }

    .ProductCat__slider {
        width: 90%;
        margin: auto;
        overflow: hidden;

        .slick-slider {
            width: 80% !important;

            .slick-prev {
                left: -10%;
                top: 45%
            }

            .slick-next {
                right: -10%;
                top: 45%
            }

            .slick-list {
                overflow: initial;
            }
        }

        .ProductCat__slider__item {
            max-width: 300px;

            .Slider__image {
                img {
                    width: 100%;
                    // height: auto;
                }
            }

            .ProductCat__title {
                letter-spacing: 0px;
                color: #0da5e8;
                font-size: 20px;
                font-weight: bolder;
                line-height: 28px;
                padding: 25px 10px 0 10px;
                max-height: 82px;
                min-height: 82px;
                text-overflow: ellipsis;
                cursor: pointer;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .ProductCat__image:hover {
                border: 2px solid #c5c5c5;
                border-radius: 2px;
            }
        }
    }
}

.ProductCat__block {
    padding: 7em 0px 3em 0px;
    margin-top: 5em;
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 7px 7px;
    background: url("../../../assects/images/home/background-Grupo-81-left.png") left bottom no-repeat,
        url("../../../assects/images/home/background-Grupo-81-right.png") right top no-repeat;
    background-size: 25%;
    overflow: hidden;
}

.Category__container .ProductCat__block {
    margin-top: 0px;
}

@media only screen and (max-width: 1680px) {
    .ProductCat__block {
        padding: 5em 0px 3em 0px;
    }
}

@media only screen and (max-width: 1440px) {
    .ProductCat__section .ProductCat__slider .ProductCat__slider__item .ProductCat__title {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1366px) {
    .ProductCat__section {
        .ProductCat__content {
            .ProductCat__title {
                font-size: 28px;
                line-height: 36px;
            }

            .ProductCat__text {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .ProductCat__section {
        .ProductCat__content {
            .ProductCat__title {
                font-size: 24px;
                line-height: 32px;
            }

            .ProductCat__text {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }

    .ProductCat__block {
        padding: 4em 15px 3em 15px;
        margin-top: 3rem;

    }
}

@media only screen and (max-width: 900px) {
    .ProductCat__section {
        .ProductCat__slider {
            width: 100%;

            .ProductCat__slider__item {
                max-width: 90%;
                margin: auto;

                .ProductCat__title {
                    font-size: 16px;
                    font-weight: 400;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .ProductCat__section {
        .ProductCat__content {
            margin: 2rem auto;

            .ProductCat__title {
                font-size: 22px;
            }
        }
    }

    .ProductCat__block {
        padding: 3em 15px;
        margin-top: 2.5rem;
    }
}

@media only screen and (max-width: 425px) {
    .ProductCat__section {
        .ProductCat__content {
            margin: 2rem auto;

            .ProductCat__title {
                font-size: 22px;
            }
        }
    }


    .ProductCat__block {
        padding: 1.5em 15px 2em;
        margin-top: 2.5rem;
    }
}