.FullWithBanner__section {
    .Gallery__block {
        position: relative;

        .Gallery__content {
            position: absolute;
            bottom: 20px;
            left: 30px;
            padding: 15px;

            .Gallery__title {
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                background-color: #0da5e8;
                padding: 5px 15px;
                // text-align: center;
            }

            .Gallery__desc {
                letter-spacing: 0px;
                color: #FFFFFF;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                padding: 8px 10px;
                position: relative;
                max-width: 350px;
                background-color: #000;
                text-overflow: ellipsis;
                cursor: pointer;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                max-height: 64px;

                span {
                    position: inherit;
                    z-index: 1;
                }

                p {
                    margin: 0;
                }

                a {
                    color: #FFF;
                }
            }
        }

        .ohmypop__Gallery__content {
            position: absolute;
            bottom: 40%;
            left: 10%;
            padding: 15px;

            .Gallery__title {
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                background-color: #0da5e8;
                padding: 5px 15px;
                // text-align: center;
            }

            .Gallery__desc {
                letter-spacing: 0px;
                color: #FFFFFF;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                padding: 8px 10px;
                position: relative;
                max-width: 350px;
                background-color: #000;
                text-overflow: ellipsis;
                cursor: pointer;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                max-height: 64px;

                span {
                    position: inherit;
                    z-index: 1;
                }

                p {
                    margin: 0;
                }

                a {
                    color: #FFF;
                }
            }
        }

        .prdog__Gallery__content {
            position: absolute;
            bottom: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            padding: 15px;

            .Gallery__title {
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                background-color: #0da5e8;
                padding: 5px 15px;
                width: 75%;
                text-align: center;
                margin: auto;
                margin-bottom: 0.5rem;
            }

            .Gallery__desc {
                letter-spacing: 0px;
                color: #FFFFFF;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                padding: 8px 10px;
                position: relative;
                max-width: 350px;
                background-color: #000;
                text-overflow: ellipsis;
                cursor: pointer;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                max-height: 64px;

                span {
                    position: inherit;
                    z-index: 1;
                }

                p {
                    margin: 0;
                }

                a {
                    color: #FFF;
                }
            }
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}


@media only screen and (max-width: 1366px) {
    .FullWithBanner__section {
        .Gallery__block {
            .Gallery__content {
                .Gallery__title {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 500;
                }

                .Gallery__desc {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .FullWithBanner__section {
        .Gallery__block {
            .Gallery__content {
                bottom: 0;
                left: 0;

                .Gallery__title {
                    font-size: 18px;
                    line-height: 24px;
                }

                .Gallery__desc {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .FullWithBanner__section {
        .Gallery__block {
            img {
                min-height: 175px;
            }
        }
    }
}

.FullBannerConent__section {
    padding: 2rem 0;

    .Gallery__block {
        padding: 25px 0;
        display: flex;

        .Gallery__content {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .Gallery__content_left {
                width: 60%;

                .Gallery__title {
                    font-size: 32px;
                    line-height: 40px;
                    font-weight: 600;
                    color: #0da5e8;
                    text-transform: uppercase;
                    display: block;
                }

                .Gallery__desc {
                    font-size: 18px;
                    line-height: 1.5rem;
                }

                .btn {
                    background-color: #000;
                    border: none;
                    color: #fff;
                    font-size: 18px;
                    line-height: 26px;
                    margin: 20px 0;
                    padding: 10px 40px;
                }
            }

        }



        .Gallery__image {
            width: 50%;

            img {
                width: 100%;
                // height: auto;
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .FullBannerConent__section {
        .Gallery__block {
            .Gallery__content {
                .Gallery__content_left {
                    width: 75%;

                    .Gallery__title {
                        font-size: 28px;
                        line-height: 38px;
                    }

                    .Gallery__desc {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .btn {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }

            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .FullBannerConent__section {
        .Gallery__block {
            .Gallery__content {
                .Gallery__content_left {
                    width: 95%;

                    .Gallery__title {
                        font-size: 24px;
                        line-height: 34px;
                    }

                    .Gallery__desc {
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 1rem;
                    }

                    .btn {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .FullBannerConent__section {
        .Gallery__block {
            .Gallery__content {
                .Gallery__content_left {
                    width: 95%;

                    .Gallery__title {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .FullBannerConent__section {
        .Gallery__block {
            flex-wrap: wrap;

            .Gallery__content {
                width: 100%;
            }

            .Gallery__image {
                width: 100%;

                img {
                    height: auto;
                }
            }
        }
    }
}

.FullBannerConent__section {
    .contentBanner__block {
        .contentBanner__content {
            text-align: center;
            max-width: 1200px;
            margin: auto;

            .contentBanner__title {
                letter-spacing: 0px;
                color: #0DA5E8;
                text-transform: uppercase;
                font-size: 32px;
                line-height: 40px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .contentBanner__text {
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
                letter-spacing: 0px;
                color: #2b2b2b;
            }
        }

        .contentBanner__gallery {
            position: relative;

            .contentBanner__gallery__content {
                position: absolute;
                bottom: 20px;
                left: 30px;
                padding: 1rem;

                .Gallery__title {
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;
                    background-color: #0da5e8;
                    padding: 5px 15px;
                    // text-align: center;
                }

                .Gallery__desc {
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    padding: 5px 15px;
                    position: relative;
                    max-width: 350px;

                    background-color: #000;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                    p {
                        margin: 0;
                    }
                }
            }

            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .FullBannerConent__section {
        .contentBanner__block {
            .contentBanner__gallery {
                position: relative;

                .contentBanner__gallery__content {
                    padding: 15px;
                    bottom: 0px;
                    left: 0px;

                    .Gallery__title {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .Gallery__desc {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .FullBannerConent__section {
        .contentBanner__block {
            .contentBanner__gallery {
                position: relative;

                img {
                    min-height: 175px;
                }

                .contentBanner__gallery__content {
                    .Gallery__title {
                        font-size: 18px;
                        line-height: 24px;
                    }

                    .Gallery__desc {
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.prodg__Gallery__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .Gallery__title {
        letter-spacing: 0px;
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        background-color: #0da5e8;
        padding: 5px 15px;
        text-align: center;
    }

    .Gallery__desc {
        letter-spacing: 0px;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        padding: 8px 10px;
        position: relative;
        max-width: 350px;
        background-color: #000;
        text-overflow: ellipsis;
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 64px;

        span {
            position: inherit;
            z-index: 1;
        }

        p {
            margin: 0;
        }

        a {
            color: #FFF;
        }
    }
}

@media only screen and (max-width:992px) {
    .ohmypop__Gallery__content {
        bottom: 20% !important;
    }
}

@media only screen and (max-width:768px) {
    .Gallery__block {
        display: flex;
        flex-direction: column;
    }

    .Gallery__block_banner3 {

        .Gallery__content,
        .Gallery__image {
            width: 100% !important;
        }
    }
}