p {
    margin-bottom: 0px;
}
.banner4_content_head{

    .banner4_content{
        text-align: center;
        max-width: 920px;
        margin: auto;
        padding: 3rem 2rem;

        .banner4_content_title{
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: 600px) {
    .banner4_content_head .banner4_content {
        padding: 1rem;
    }
}