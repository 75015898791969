.CategoryContent__Section {
    margin: 7em auto;
    max-width: 1680px;
    padding: 0 15px;

    .CategoryContent_row {
        display: flex;
        justify-content: space-between;

        .CategoryContent_col {
            width: 47%;

            h2.text-uppercase {
                color: #0da5e8;
                font-size: 26px;
                line-height: 34px;
                font-weight: 600;
                min-height: 68px;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .CategoryContent__Section {
        margin: 4rem auto;
    }
}

@media only screen and (max-width: 1366px) {
    .CategoryContent__Section {
        margin: 2rem auto;

        .CategoryContent_row {
            .CategoryContent_col {
                h2.text-uppercase {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .CategoryContent__Section {
        .CategoryContent_row {
            flex-direction: column;
            row-gap: 25px;

            .CategoryContent_col {
                width: 100%;

                h2.text-uppercase {
                    min-height: auto;
                    font-size: 22px;
                    line-height: 28px;
                    font-weight: 500;
                    margin-top: 15px;
                }
            }
        }
    }
}