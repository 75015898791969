$defaultColor: #0da5e8;
.popupmodal {
    min-width: 320px;
    max-width: 1024px;
    width: 100%;
    .MobileView {
        display: none;
    }

    .popupaddressmodal__content {
        height: max-content;

        .popupaddressmodal__title {
            text-align: center;
            background: $defaultColor;
            color: white;
            padding: 10px 0;
            margin: 0;
            position: relative;

            .close__icon {
                position: absolute;
                right: 15px;
                top: 7px;
                cursor: pointer;
            }
        }
        .ppm__tab {
            display: flex;
            gap: 1em;
            justify-content: flex-start;
            align-items: center;

            span {
                cursor: pointer;
                background-color: white;
                color: $defaultColor;
                border: 1px solid $defaultColor;
                padding: 10px;
                border-radius: 10px;
            }

            .selectedTab {
                background-color: $defaultColor;
                color: white;
            }
        }
        .popupmodal__form {
            display: flex;
            gap: 10px;
            padding: 2em 10px;
            border: 1px solid #d2d2d2;
            justify-content: space-evenly;
            height: 100%;
            overflow: scroll;

            & > * {
                width: calc(100% / 2 - 3em);

                h5 {
                    color: $defaultColor;
                    text-decoration: underline;
                }
            }

            .ppm__content, .ppm__right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .ppm__contact, .ppm__address {
                    label {
                        // margin-bottom: 1em;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        input, select {
                            height: 40px;
                            border: none;
                            background-color: #f3f3f3;
                            margin: 10px 0;
                            width: 100%;
                            padding-left: 10px;
                        }
                    }

                    .ppm__checkbox {
                        flex-direction: row;
                        justify-content: flex-start;
                        // margin: 0;
                        align-items: center;
                        gap: 10px;
                        input {
                            margin: 0;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .ppm__content {
                height: 100%;
            }
            .ppm__right {
                justify-content: unset;
            }
        }

        .ppmodal__success {
            text-align: center;
            color: $defaultColor;
        }
    }
}

.ppl__buttons {
    margin-bottom: 4em;
    display: flex;
    justify-content: center;
    width: 80%;
    gap: 1em;
    button {
        background-color: #000;
        color: white;
        padding: 10px 2em;
        font-size: 14px;
    }
    .submitenabled {
        background-color: #000;
    }
    .submitdisabled {
        background-color: $defaultColor;
    }
}

@media(max-width: 768px) {
    .popupmodal {
        .popupaddressmodal__content {
            overflow: scroll;
            .popupmodal__form {
                flex-direction: column;

                & > * {
                    width: 100%;
                }
                .ppm__content {
                    .ppm__contact {
                        label {
                            input {
                                width: 100%;
                            }
                        }
                    }
                }

                .ppm__right {
                    .ppm__address {
                        label {
                            input, select {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .ppl__buttons {
        width: 100%;
        margin-bottom: 1em;
    }
}

@media(max-width: 580px) {
    .popupmodal {
        .popupaddressmodal__content {
            width: 95%;
        }
    } 
    .MobileView {
        display: block;
    }
    .ppm__checkbox {
        margin: 2em 0;
    }
    .ppl__buttons {
        flex-direction: column;
    }
}