.product__block{
    box-shadow: rgba(99, 99, 99, 30%) 0px 2px 8px 0px;
    position: relative;
    overflow: hidden;
    padding: 10px;
    width: 320px;

    .product__lable {
        .pre__sale {
            width: 100%;
            display: block;
            position: absolute;
            background-color: #007042;
            color: #FFF;
            text-transform: uppercase;
            letter-spacing: 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            transform: rotate(-45deg);
            top: 6%;
            left: -38%;
            z-index: 1;
            justify-content: center;
        }

        .offer__label {
            width: 100%;
            display: block;
            position: absolute;
            background-color: #eb004b;
            color: #FFF;
            text-transform: uppercase;
            letter-spacing: 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            transform: rotate(45deg);
            top: 6%;
            right: -38%;
            z-index: 1;
            justify-content: center;
        }
    }

    .product__img__block {
        padding: 15px;
        position: relative;
        overflow: hidden;
        perspective: 1000px;

        // .product__image {
        //     width: 100%;    
        //     transform-style: preserve-3d;
        //     transform: rotateX(0deg) rotateY(0deg);
        //     transition: all 2s ease;
        // }

        // &:hover .product__image{
        //     transform: rotateX(0turn) rotateY(1turn) scale(1.2);
        // }

        .product__cart_success {
            position: absolute;
            top: 50%;
            width: 90%;
            background-color: #0da5e8;
            padding: 10px;
            border-radius: 23px;
            color: #FFF;
            display: flex;

            .product__cart_mgs {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                }
            }
        }
    }

    .product__cart {
        transform: translateY(0px);
        transition: 0.5s all;
        background-color: #000000;
        opacity: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-right: -15px;
        margin-left: -15px;

        position: absolute;
        bottom: 0;
        width: 100%;

        .cart__action {
            width: 60%;

            .btn__addtocart {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                width: 100%;
                font-size: 14px;
                position: relative;
                padding: 9px;

                span {
                    margin-left: 20px;
                }

                .cart__icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 7px;
                    position: absolute;
                    left: 10px;
                }
            }
        }

        .cart__input__block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 40%;

            .btn__decrease,
            .btn__increase {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 26%;
                min-height: 38px;

                .inde__icon {
                    color: #FFFFFF;
                    width: 20px;
                    height: 20px;
                }
            }

            .cart__input {
                width: 48%;
                height: 39px;
                text-align: center;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
            }
        }

        .cart__btn {
            border: none;
            background-color: transparent;
            outline: none;
            box-shadow: none;
            padding: 7px;

            &:hover {
                background-color: #0da5e8;
            }
        }
    }

    .product__desc {
        margin: 10px 0 15px;

        .product__row {
            display: flex;
            margin-bottom: 3px;
            padding-left: 5px;

            .product__name {
                width: 75%;
                font-size: 14px;
                padding-right: 5px;
                max-height: 42px;
                min-height: 42px;
                cursor: pointer;

                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                text-align: left;
            }

            .product__sku {
                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 14px;

                width: 25%;
                padding: 0 5px;
                background-color: #e6e6e6;
            }

            .distributor__price {
                width: 50%;
                font-size: 14px;
                padding: 5px 0;
            }

            .product__brand {
                width: 50%;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                padding: 5px 0;
            }

            .product__brand{
                color: #0da5e8;
                text-align: left;
            }

            .product__discount {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25%;
                padding: 5px;

                font-size: 14px;
                color: red;
                text-decoration: line-through;
            }

            .product__price {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                width: 25%;
                padding: 5px;
                color: #FFF;
                background-color: #0da6ea;
            }
        }

        .distributor__row {
            background-color: #0da6ea;

            .distributor__price {
                color: #FFF;
                text-align: left;
            }

            .product__discount {
                background-color: #FFF;
            }
        }

        .currency {
            margin-left: 3px;
        }
    }

    &:hover {
        // .product__img__block {   
        //     .product__image {
        //         transform: scale(1.2);
        //     }
        // }

        .product__cart {
            opacity: 1;
            z-index: 1;
        }
    }
}

@media only screen and (max-width: 850px) {

    .product__block .product__cart {
        opacity: 1;
        z-index: 1;
    }
}