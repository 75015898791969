.BestSeller__section {
    .BestSeller__content {
        text-align: center;
        max-width: 1200px;
        margin: auto;

        .BestSeller__title {
            letter-spacing: 0px;
            color: #0DA5E8;
            text-transform: uppercase;
            font-size: 32px;
            line-height: 40px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .BestSeller__text {
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
            letter-spacing: 0px;
            color: #2b2b2b;
        }
    }

    .BestSeller__products {
        display: flex;
        width: 90%;
        margin: auto;
        overflow: hidden;
        margin-bottom: 4em;

        .slick-slider {
            .slick-list {
                overflow: inherit;
            }
    
            .slick-prev {
                left: -3%;
            }
    
            .slick-next {
                right: -3%;
            }
        }
        
        .product__block {
            margin: 20px auto;
        }
    }

    .products__skeleton {
        width: 90%;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
    }
}

.BestSeller__block {
    padding: 7em 0px 3em 0px;
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 7px 7px;
    background: url("../../../assects/images/home/background-Grupo-81-left.png") left bottom no-repeat,
      url("../../../assects/images/home/background-Grupo-81-right.png") right top no-repeat;
    background-size: 25%;
    overflow: hidden;
    background-color: #FFF;
}


@media only screen and (max-width: 1440px) {
    .BestSeller__block {
        padding: 5em 0px 2em 0px;
    }
}


@media only screen and (max-width: 1366px) {
    .BestSeller__section {
        .BestSeller__content {
            margin: 2rem auto;
            .BestSeller__title {
                font-size: 28px;
                line-height: 36px;
            }
    
            .BestSeller__text {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }

    .BestSeller__block {
        padding: 3em 15px 1em 15px;
    }
}

@media only screen and (max-width: 992px) {
    .BestSeller__section {
        .BestSeller__content {
            .BestSeller__title {
                font-size: 24px;
                line-height: 32px;
            }
    
            .BestSeller__text {
                font-size: 16px;
                line-height: 26px;
            }
        }
    
    }
}

@media only screen and (max-width: 850px) {
    .BestSeller__section {
        .BestSeller__products{
            .slick-slider{
                width: 100% !important;
            
                .slick-prev {
                    left: -0%;
                }
        
                .slick-next {
                    right: -0%;
                }
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .BestSeller__section {
        .BestSeller__products{
            width: 100%;
        }
    }
}


@media only screen and (max-width: 600px) {
    .BestSeller__section {
        .BestSeller__content {
            margin: 2rem auto;
            .BestSeller__title {
                font-size: 22px;
            }
        }
    }

    .BestSeller__block {
        padding: 1em 15px 0.4em 15px;
    }
}

@media only screen and (max-width: 425px) {
    .BestSeller__section {
        .BestSeller__content {
            margin: 2rem auto;
            .BestSeller__title {
                font-size: 22px;
            }
        }
    }
}


@media only screen and (max-width: 375px) {
    .BestSeller__section {
        .BestSeller__products{
            .slick-slider{
                width: 100% !important;
                .slick-list{
                    .slick-track{
                        .slick-slide {
                            .product__block{
                                width: 100%;
                                box-shadow: none;
                            }
                        }
                        .slick-slide.slick-current {
                            >div{
                                padding: 2px;
                            }
                            .product__block{
                                box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .BestSeller__block {
        padding: 1em 15px 0 15px;
    }
}
