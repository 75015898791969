.sideNav__section {
    position: fixed;
    z-index: 99;
    top: 0;
    left: -40vw;
    transition: left 1s;

    &.sideNav__section__open {
        position: fixed;
        opacity: 1;
        left: 0;
        z-index: 99;
        transition: left 1s;
    }

    .sideNav__block {
        width: 35vw;
        background-color: white;
        height: 100vh;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,rgba(0, 0, 0, 0.05) 0px 5px 10px;

        .sideNav__Header {
            display: flex;
            padding: 15px 20px;
            justify-content: space-between;
            align-items: center;
            background-color: #000;

            .sideNav__Header__title {
                font-size: 18px;
                text-transform: capitalize;
                color: #FFF;
            }

            .button__close {
                background-color: transparent;
                border: none;
                color: #FFFF;
                display: flex;
                align-items: center;
                padding: 0;
                
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .sideNav__Body {
            .sideNav__Menu {
                padding: 0;
                margin: 20px 10px;

                .skeleton__li {
                    padding: 6px 10px;
                }

                .sideNav__Main__Menu {
                    border-bottom: 1px solid rgba(210,210,210,.34);
                    display: flex;
                    justify-content: space-between;

                    .sideNav__Main__Menu__Link{
                        color: #0DA5E8;
                        padding: 1rem;
                        display: block;
                        width: 80%;
                    }

                    .sideNav__Main__Menu__Nav {
                        width: 20%;
                        background: none;
                        border: none;
                        outline: none;

                        svg {
                            color: #0DA5E8;
                        }
                    }
                }
            }
        }

        .BrandMenu__Header {
            display: flex;
            padding: 10px 15px;
            align-items: center;
            background-color: #0DA5E8;
            cursor: pointer;

            .BrandMenu__Header__title {
                font-size: 18px;
                text-transform: capitalize;
                color: #FFF;
                display: flex;
                gap: 4px;
            }
    
            .BrandMenu__Button__Close {
                background-color: transparent;
                border: none;
                color: #FFFF;
                display: flex;
                align-items: center;
                padding: 0;
                margin-right: 15px;
                
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .BrandMenu__Body{
            .sideNav__Menu {
                margin: 10px;
                max-height: 38.7vh;
                overflow: auto;
                .sideNav__Main__Menu {
                    .sideNav__Main__Menu__Link {
                        padding: 12px 16px;
                    }
                }
            }

            .Brand__section {
                box-shadow: none;

                .Brand__Block {
                    max-width: 100%;
                    padding: 0px;

                    .Brand__slider__block {
                        display: block;

                        .slider__block__col {
                            width: 40%;
                            margin: auto;

                            .brand__parent {
                                margin: 10px;
    
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        } 
                    }
                }
            }
        }

        .ProductMenu__Body {
            .sideNav__Menu {
                margin: 10px;
                max-height: 38.7vh;
                overflow: auto;
                .sideNav__Main__Menu {
                    .sideNav__Main__Menu__Link {
                        padding: 12px 16px;
                    }
                }
            }
        }
    }
}

.sideNav__back__section {
    display: none;
    &.sideNav__section__open {
        display: block;
        position: fixed;
        opacity: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        z-index: 98;
        transition: left 1s;
        background-color: gray;
    }
}


@media only screen and (max-width: 992px) {
    .sideNav__section {
        left: -55vw;

        .sideNav__block {
            width: 50vw;

            .BrandMenu__Body{
                .Brand__section {
                    .Brand__Block {
                        .Brand__slider__block {
                            .slider__block__col {
                                width: 50%;
                            } 
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .sideNav__section {
        left: -75vw;

        .sideNav__block {
            width: 70vw;
        }
    }
}

@media only screen and (max-width: 425px) {
    .sideNav__section {
        left: -90vw;

        .sideNav__block {
            width: 85vw;
        }
    }
}


@media only screen and (max-width: 375px) {
    .sideNav__section {
        .sideNav__block {
            .BrandMenu__Header {
                .BrandMenu__Header__title {
                    font-size: 14px;
                }
            }

            .ProductMenu__Body,
            .BrandMenu__Body {
                .sideNav__Menu {
                    .sideNav__Main__Menu {
                        .sideNav__Main__Menu__Link {
                            font-size: 14px;
                        }
                    } 
                }
            }
        }
    }
}