.BrandAndDis__section {
    position: relative;
    max-width: 1830px;
    margin: auto;
    margin-bottom: 2rem;

    .BrandAndDis__container {
        width: 100%;

        .BrandAndDis__row {
            display: flex;
            justify-content: space-between;

            .brand-left-img {
                width: 68%;
                display: flex;
            }

            .BrandAndDis__advantage {
                width: 68%;
                position: relative;
                max-height: 454px;

                .BrandAndDis__content {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;

                    h2 {
                        font-size: 32px;
                        line-height: 40px;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: #FFF;
                    }

                    .BrandAndDis__content__des {
                        position: relative;
                        padding: 10px;

                        a {
                            color: #FFF;
                            font-size: 18px;
                        }

                        p {
                            margin: 0;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-color: #FFF;
                            opacity: 0.3;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .BrandAndDis__identifier {
                width: 30%;
                display: flex;

                .identifier__block {
                    width: 100%;
                    background: url('../../../assects/images/home/skyblue-bg.png') repeat;
                    padding: 7%;
                    display: block;

                    .mt-4 {
                        margin-top: 0 !important;
                    }


                    .dos-heading {
                        font-size: 22px;
                        color: #2b2b2b;
                        font-weight: bold;
                        line-height: 30px;
                        margin-bottom: 10px;
                    }

                    .dos-subheading {
                        font-size: 22px;
                        color: #fff;
                        font-weight: bold;
                        line-height: 30px;
                        margin-bottom: 20px;
                    }

                    .dos {
                        margin: 0px;
                        overflow: hidden;
                        padding: 0px;

                        li {
                            background-color: #fff;
                            font-weight: bold;
                            list-style-type: none;
                            margin: 0px 0px 30px 0px;
                            padding: 15px 1px 15px 15px;
                            width: 100%;
                            line-height: 2rem;

                            a {
                                text-decoration: none;
                                color: #2b2b2b;
                                display: flex;
                                align-items: center;
                                font-size: 20px;
                                line-height: 2rem;

                                img {
                                    width: 50px;
                                    height: 50px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}


@media only screen and (max-width: 1680px) {
    .BrandAndDis__section .BrandAndDis__container .BrandAndDis__row .BrandAndDis__advantage {
        max-height: inherit;
    }
}

@media only screen and (max-width: 1366px) {
    .BrandAndDis__section {
        .BrandAndDis__container {
            .BrandAndDis__row {
                .BrandAndDis__advantage {
                    .BrandAndDis__content {
                        h2 {
                            font-size: 28px;
                            line-height: 38px;
                        }

                        .BrandAndDis__content__des {
                            a {
                                color: #FFF;
                                font-size: 18px;
                            }
                        }
                    }
                }

                .BrandAndDis__identifier {
                    .identifier__block {
                        .dos-heading {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        .dos-subheading {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        .dos {
                            li {
                                a {
                                    font-size: 18px;
                                    line-height: 26px;

                                    img {
                                        width: 45px;
                                        height: 45px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .BrandAndDis__section {
        .BrandAndDis__container {
            .BrandAndDis__row {
                .BrandAndDis__advantage {
                    width: 60%;

                    .BrandAndDis__content {
                        h2 {
                            font-size: 24px;
                            line-height: 34px;
                        }

                        .BrandAndDis__content__des {
                            a {
                                color: #FFF;
                                font-size: 16px;
                            }
                        }
                    }
                }

                .BrandAndDis__identifier {
                    width: 30%;

                    .identifier__block {
                        .dos-heading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos-subheading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos {
                            li {

                                a {
                                    font-size: 16px;
                                    line-height: 24px;

                                    img {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .BrandAndDis__section {
        .BrandAndDis__container {
            .BrandAndDis__row {
                flex-wrap: wrap;

                .BrandAndDis__advantage {
                    width: 100%;
                }

                .BrandAndDis__identifier {
                    margin-top: 15px;
                    width: 100%;
                }

                .brand-left-img {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .BrandAndDis__section {
        .BrandAndDis__container {
            .BrandAndDis__row {
                .BrandAndDis__advantage {
                    width: 100%;

                    img {
                        min-height: 200px;
                    }

                    .randd-cont-heading {
                        font-size: 22px;
                        line-height: 30px;
                    }

                    .randd-cont-services {
                        li {
                            padding-right: 30px;

                            img {
                                width: 55px;
                                height: auto;
                            }
                        }
                    }
                }

                .BrandAndDis__identifier {
                    width: 100%;

                    .identifier__block {
                        .dos-heading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos-subheading {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        .dos {
                            li {

                                a {
                                    font-size: 16px;
                                    line-height: 24px;

                                    img {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .BrandAndDis__section {
        .BrandAndDis__container {
            // padding: 2rem 0;

            .BrandAndDis__row {
                .BrandAndDis__advantage {
                    .randd-cont-heading {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .randd-cont-services {
                        li {
                            width: 100%;

                            img {
                                width: 45px;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}