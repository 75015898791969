$defaultColor: #0da5e8;
.plp {
    max-width: 1680px;
    width: 100%;
    margin: 0 auto;
    padding: 3% 15px;

    .btn-accordion.collapsed {
        background-color: #f0f0f0;
    }

    .plp__breadcrumbs {
        text-transform: uppercase;
    }
      
    .plp__breadcrumbs p a {
        color: $defaultColor;
        font-size: 18px;
    }
    
    .plp__container {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-bottom: 2em;

        .plp__header_content, .plp_footer{
            *{
                text-align: justify;
            }
            h1,h2,h3,h4,h5,h6{
                text-align: left;
            }
        }
        .plp_footer {
            margin-top: 3rem;

            .row {
                width: 100%;
            }

            ul {
                padding: 0;

                li {
                    list-style: none;
                    margin: 6px 0;

                    a {
                        color: #2b2b2b;
                    }
                }
            }
        }

        .showAcc {
            display: block !important;
        }
        
        // .notShowAcc {
        //     display: flex;
        // }

        .nonebutton {
            pointer-events: none;
        }

        .plp__filter__skeleton {
            width: 320px;
        }

        .spinner-grow {
            span {
                width: 5em;
                height: 5em;
            }
        }

        .plp__filters {
            width: 314px;

            .plp__filterheading {
                background-color: $defaultColor;
                color: white;
                font-size: 20px;
                padding: 10px;
                margin: 0;
                margin-bottom: -3px;
                height: 3em;
                display: flex;
                align-items: center;
                z-index: 6;
                position: relative;
                border-radius: 4px 4px 0 0;
            }
            .accordion {
                margin-bottom: 2em;
            }
            .accordion-item {
                h2 {
                    background-color: #DEDEDE;

                    button {
                        background-color: #fff;
                        color: $defaultColor;
                        font-size: 14px;
                        font-weight: 600;
                        &:focus {
                            box-shadow: none !important;
                        }
                    }
                }

                .accordion-body {
                    display: flex;
                    flex-direction: column;

                    label {
                        font-size: 14px;
                        margin-bottom: 10px;
                        &:hover{
                            color: #0da5e8
                        }

                        input {
                            margin-right: 10px;
                        }
                    }
                }

                .plp__colors {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    align-items: center;
                    flex-direction: row;

                    span {
                        position: relative;

                        input {
                            visibility: hidden;
                            position: absolute;

                            &:checked {
                                visibility: visible;
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                            }
                        }
                        input[type="checkbox"] {
                            margin-top: 6px !important;
                            /* Add if not using autoprefixer */
                            -webkit-appearance: none;
                            /* Remove most all native input styles */
                            appearance: none;
                            /* For iOS < 15 */
                            background-color: var(--form-background);;
                            /* Not removed via appearance */
                            margin: 0;
                          
                            font: inherit;
                            color: currentColor;
                            width: 1.15em;
                            height: 1.15em;
                            // border: 0.15em solid #0f0;
                            border-radius: 0.15em;
                            transform: translateY(-0.075em);

                            display: grid;
                            place-content: center;
                          }
                          
                          input[type="checkbox"]::before {
                            content: "";
                            width: 0.65em;
                            height: 0.65em;
                            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                            transform: scale(0);
                            transform-origin: bottom left;
                            transition: 120ms transform ease-in-out;
                            box-shadow: inset 1em 1em var(--form-control-color);
                            background-color: #37bfa7;
                          }
                          
                          input[type="checkbox"]:checked::before {
                            transform: scale(1);
                          }
                          
                          input[type="checkbox"]:disabled {
                            --form-control-color: var(--form-control-disabled);
                          
                            color: var(--form-control-disabled);
                            cursor: not-allowed;
                          }
                        label {
                            margin: 0;
                        }
    
                        img {
                            max-height: 20px;
                            max-width: 20px;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .plp__content {
            display: flex;
            flex-direction: column;      
            max-width: 1320px;
            max-width: calc(100% - 330px);
            width: 100%; 
            overflow: auto;

            & > h3 {
                font-size: 32px;
                .react-loading-skeleton {
                    width: 100% !important;
                }
            }

            .plp__count {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .plp__paginationView {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    span {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding-right: 8px;
                        justify-content: flex-end;

                        svg {
                            background-color: grey;
                            color: white;
                            outline: 1px solid gray;
                            outline-offset: 2px;
                            cursor: pointer;
                        }

                        .selected {
                            background-color: $defaultColor;
                            outline: 1px solid $defaultColor;
                            outline-offset: 2px;
                        }
                    }
                }
            }

            .plp__banner {
                width: 100%;
                margin: 0;
                margin-bottom: 1em;
            }
            
            .plp__sorting {
                margin: 1em 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;

                .dropdown-toggle {
                    background-color: #fff;
                    color: #2b2b2b;
                    text-transform: uppercase;
                    &::after {
                        display: inline-block;
                        margin-left: 0.255em;
                        vertical-align: 0.255em;
                        content: "";
                        border-top: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-bottom: 0;
                        border-left: 0.3em solid transparent;
                    }
                    .dropdown-menu {
                        border: 1px solid grey;
                    }
                }

                .plp__filterText {
                    margin-right: 10px;
                }

                svg {
                    margin-left: 1em;
                    cursor: pointer;
                }
            }

            .plp__noCount {
                height: 10em;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                color: $defaultColor;
            }

            .plp__grid {
                margin-top: 2em;
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                align-items: center;
                padding: 0 1px;

                .product__block {
                    width: calc((100% - 32px) / 4);
                }
                
                .infinite-scroll-component__outerdiv {
                    width: 100%;

                    .infinite-scroll-component  {
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;
                        align-items: baseline;
                        justify-content: flex-start;
                        padding: 1px 1px 15px 1px;
                    }

                    span {
                        display: flex;
                        gap: 3px;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                }
            } 
        }
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
      
    .page-item {
        display: inline-block;
        margin-right: 5px;
        width: 2em;
        text-align: center;

        a {
            color: $defaultColor;
            font-size: 14px;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .break {
        margin-right: 6px;
    }
      
    .page-link {
        cursor: pointer;
        border: 1px solid #ccc;
        padding: 5px;
    }
      
    .page-link:hover {
        background-color: #f5f5f5;
    }
    
    .active .page-link {
        background-color: $defaultColor;
        color: #fff;
        border-color: $defaultColor;
    }

    .plp__mobile__filter { 
        display: none;
    }

    .plp__mobile__filter__back {
        display: none;
    }
      
}

@media only screen and (max-width: 1600px) {
    .plp {
        .plp__container { 
            .plp__content { 
                .plp__grid {
                    .product__block {
                        width: calc((100% - 20px) / 3);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1260px) {
    .plp {
        .plp__container{
            >span[aria-live="polite"]{
                display: none;
            }
            .plp__filters {
                position: fixed;
                top: 0;
                right: -55%;
                z-index: 101;
                padding: 10px;
                height: 100%;
                width: 50%;
                box-sizing: border-box;
                background-color: #FFF;
                overflow-y: scroll;
                transition: right 1s;
            }

            .plp__filters.plp__mobile__filter__open {
                right: 0;
                transition: right 1s;
            }

            .plp__mobile__filter__back.plp__mobile__filter__open {
                display: block;
                position: fixed;
                opacity: 0.2;
                left: 0;
                bottom: 0;
                height: 100vh;
                width: 100vw;
                z-index: 98;
                transition: left 1s;
                background-color: gray;
            }

            .plp__mobile__filter {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 25px;
        
                .plp__mobile__btn {
                    background-color: #2b2b2b;
                    border: none;
                    outline: none;
                    padding: 10px 22px;
                    border-radius: 5px;
                    color: #FFF;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;
                    text-transform: capitalize;
                }
            }

            .plp__content { 
                max-width: calc(100% - 0px);
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .plp {
        .plp__container { 
            .plp__content { 
                .plp__grid {
                    .product__block {
                        width: calc((100% - 10px) / 2);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .plp {
        .plp__container{
            .plp__filters {
                right: -75%;
                width: 70%;
            }

            .plp__count {
                .plp__paginationView {
                    width: 100%;
                }
            }

            .plp__content { 
                .plp__grid {
                    .product__block {
                        width: 320px;
                        margin: auto;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 580px) {
    .plp {
        .plp__container{
            .plp__content > .plp_footer {
                table{
                    tbody,tr{
                        background-color: unset !important;
                        td{
                            display: revert !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 475px) {
    .plp {
        .plp__container{
            .plp__filters {
                right: -85%;
                width: 81%;
            }
        }
    }
}

@media only screen and (max-width: 365px) {
    .plp {
        padding: 35px 15px;
    }
}

