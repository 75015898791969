$defaultColor: #0da5e8;

.myaccount {
    max-width: 1680px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 3% 15px;

    .mobileView {
        display: none;
    }

    .desktopView {
        display: block;
    }

    .myaccount__container {
        // margin: 0 3%;

        &>img {
            max-width: 100%;
        }

        .myaccount__breadcrumbs {

            margin: 1em 0;

            .myaccount__home {
                color: gray;
            }
        }

        .myaccount__title {
            color: $defaultColor;
            font-size: 30px;
            font-weight: 600;
            margin: 1em 0;
        }

        .myaccount__customerarea {
            table {
                max-width: 1680px;
                width: 100%;
                margin: 0 auto;
                background-color: #f3f3f3;
                padding: 10px;

                tr {
                    border-bottom: 1px solid lightgrey;
                    width: 95%;
                    margin: 0 auto;
                    display: flex;

                    &:last-child {
                        border: none !important;
                    }

                    &>* {
                        width: calc(100% / 2);
                    }

                    td {
                        text-align: center;

                        p {
                            margin: 10px 0;
                            display: flex;

                            span {
                                width: calc(100% / 2);
                            }

                            .myaccount__label {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: left;
                            }

                            .myaccount__value {
                                color: $defaultColor;
                                font-size: 14px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        .myaccount__tabs {
            margin-top: 2em;

            ul {
                list-style: none;
                display: flex;
                // gap: 4px;
                align-items: center;
                // border-bottom: 1px solid #AAAAAA;
                padding: 0;
                margin: 0;

                @mixin forLI {
                    cursor: pointer;
                    border: 1px solid #f1f1f1;
                    padding: 10px;
                    // border-top-right-radius: 10px;
                    // border-top-left-radius: 10px;
                    border-bottom: 0;
                    transition: color 1s, background 1s;
                }

                .li__notselected {
                    @include forLI();
                    color: #2b2b2b;

                    &:hover {
                        color: $defaultColor;
                        background-color: #f1f1f1;
                    }
                }

                .li__selected {
                    @include forLI();
                    color: $defaultColor;
                    background-color: #f1f1f1;
                    // font-weight: 600;
                }
            }
        }

        .myaccount__tabContent {
            margin-bottom: 4em;
            padding: 10px;
            background-color: #f1f1f1;
        }

        .myaccount__text {
            border: 1px solid lightgray;
            padding: 10px;
            border-radius: 10px;
        }
    }
}

@media (max-width: 480px) {
    .myaccount {

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        .mobileView {
            display: block;
            margin-bottom: 1em;

            .accordion-button:not(.collapsed),
            .accordion-button.collapsed {
                color: #2b2b2b;
                box-shadow: none;
            }

            .plp__colors__mobile ul {
                display: flex;
                flex-direction: column;

                li {
                    width: 100%;
                    border: 0 !important;
                    border-bottom: 1px solid #d2d2d2 !important;
                    background-color: white !important;
                }
            }
        }

        .desktopView {
            display: none;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        &>tr {
            border: 1px solid #ccc;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
        }

        tbody {
            tr {
                flex-direction: column;

                td {
                    width: 100% !important;
                }
            }
        }

        .myaccount__container {
            .myaccount__tabs {
                ul {
                    .li__notselected {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (min-width:480px) and (max-width: 580px) {
    .myaccount .myaccount__container .myaccount__tabs ul {
        flex-wrap: wrap;
    }
}