.Pd__details__section {
    background: #ECECEC 0% 0% no-repeat padding-box;
    box-shadow: rgba(0, 0, 0, 0.3490196078) 0px 8px 5px;
    margin-bottom: 2em;

    .Pd__details__backbutton {
        max-width: 1680px;
        width: 100%;
        margin: auto;
        padding: 0 15px 1em 15px;

        span {
            button {
                outline: none;
                border: 1px solid #E6E6E6;
                color: #474a4e;
                display: flex;
                align-items: center;
                background: #E6E6E6 0% 0% no-repeat padding-box;
                padding: 6px 20px 6px 17px;
            }

            button:hover {
                color: #FFF;
                background: #0da5e8 0% 0% no-repeat padding-box;
            }
        }


    }

    .Pd__details__container {
        max-width: 1680px;
        width: 100%;
        margin: auto;
        padding: 0 15px 2em;

        .currency {
            margin-left: 3px;
        }

        .Pd__details__block {
            border: 2px solid #707070;
            padding: 20px 15px;

            display: flex;
            justify-content: space-between;

            .Pd__details__gallery {
                width: 36%;
                position: relative;

                .Pd__details__flytocart {
                    position: absolute;
                    top: 0;
                    z-index: -1;
                }

                .Pd__details__sku {
                    position: absolute;
                    top: 25px;
                    z-index: 3;
                    left: 25px;
                    background: #E6E6E6 0% 0% no-repeat padding-box;
                    padding: 10px 23px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .react-slider__description {
                    display: none;
                }

                .react-slider__ul {
                    padding: 0;
                }

                .react-slider__ul li.active img {
                    position: inherit;
                    border: 2px solid #757474b0;
                }

                .Pd_product__lable {
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    display: list-item;
                    width: 100%;
                    overflow: hidden;
                    height: 175px;

                    .product__lable {
                        .offer__label {
                            width: 100%;
                            display: block;
                            position: absolute;
                            background-color: #eb004b;
                            color: #FFF;
                            text-transform: uppercase;
                            letter-spacing: 0px;
                            font-weight: 600;
                            font-size: 16px;
                            text-align: center;
                            transform: rotate(45deg);
                            top: 20%;
                            right: -40%;
                            z-index: 2;
                        }

                        .pre__sale {
                            width: 100%;
                            display: block;
                            position: absolute;
                            background-color: #007042;
                            color: #FFF;
                            text-transform: uppercase;
                            letter-spacing: 0px;
                            font-weight: 600;
                            font-size: 16px;
                            text-align: center;
                            transform: rotate(-45deg);
                            top: 20%;
                            left: -40%;
                            z-index: 2;
                        }
                    }
                }
            }

            .Pd__details__des {
                width: 40%;

                .Pd__details__brand {
                    letter-spacing: 0px;
                    color: #0DA5E8;
                    font-size: 22px;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }

                .Pd__details__title {
                    letter-spacing: 0px;
                    color: #2b2b2b;
                    font-size: 28px;
                    font-weight: 600;
                }

                .product__tab__cont {
                    background-color: #FFF;
                    margin: 2em 0;

                    .slantedtabs {
                        .nav-tabs {
                            .nav-link {
                                color: #000;

                                &.active {
                                    color: #fff;
                                    background-color: #aaaaaa;
                                }
                            }
                        }
                    }

                    .tab-content {
                        padding: 20px 10px;
                        color: #747575;

                        .details-cont {
                            .heading {
                                margin: 0px;
                                font-weight: bold;
                                height: 40px;
                                padding: 8px 0px;
                                background: url('./../../assects/images/ProductDetails/ds-heading-bg.jpg') no-repeat;
                            }

                            .description {
                                margin: 10px 0;

                                ul {
                                    margin: 0;
                                    padding: 0;

                                    li {
                                        div {
                                            margin: 0 !important;
                                        }

                                        list-style: none;
                                        border-bottom: 1px solid #75747452;
                                        padding: 5px 0;
                                    }
                                }

                                h3 {
                                    font-size: 18px;
                                    margin: 10px 0;
                                    font-weight: 800;
                                }
                            }
                        }
                    }
                }
            }

            .Pd__details__action {
                width: 20.5%;

                .Pd__details__action_tab {
                    display: none;
                }

                .pd__price__block.pd__price__block_b2b {
                    border: 2px solid #0da5e8;
                    background-color: #0da5e8;
                    margin-top: 5px;
                }

                .pd__price__block {
                    border: 2px solid #000;
                    background-color: #000;
                    overflow: hidden;
                    height: 90px;
                    display: flex;
                    align-items: center;

                    .pd__price__block__test {
                        color: #fff;
                        float: left;
                        width: 40%;
                        font-size: 20px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .pd__price__block__price {
                        color: #000;
                        width: 60%;
                        background-color: #ebebeb;
                        font-size: 32px;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        height: 100%;
                        justify-content: center;
                        padding: 15px;

                        .price__block {
                            display: flex;
                            flex-direction: column;
                            position: relative;
                        }

                        span.product__discount {
                            font-size: 17px;
                            position: absolute;
                            top: -15px;
                            right: 0;
                            color: red;
                            text-decoration: line-through;
                        }
                    }
                }

                .pd-vat {
                    border-bottom: 1px solid #707070;
                    padding: 4px;
                    font-size: 12px;
                    text-align: right;
                }

                .pd-vat-b2b {
                    padding: 4px;
                    font-size: 12px;
                    text-align: right;
                }

                .cart__input__block {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;
                    background-color: #000;

                    .btn__decrease,
                    .btn__increase {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 25%;
                        min-height: 46px;
                    }

                    .cart__btn {
                        border: none;
                        background-color: transparent;
                        outline: none;
                        box-shadow: none;
                        padding: 7px;
                    }

                    .cart__btn:hover {
                        background-color: #0da5e8;
                    }

                    .btn__decrease .inde__icon,
                    .btn__increase .inde__icon {
                        color: #FFFFFF;
                        width: 20px;
                        height: 20px;
                    }

                    .cart__input {
                        width: 50%;
                        height: 46px;
                        text-align: center;
                        border-left: none;
                        border-right: none;
                    }
                }

                .cart__action {
                    padding: 15px 0;
                    border-bottom: 1px solid #707070;

                    .btn__addtocart {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #FFF;
                        width: 100%;
                        background-color: #0da5e8;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        padding: 10px;
                        font-size: 20px;

                        svg {
                            color: #FFFFFF;
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                        }
                    }
                }

                .pd-usertype-cont {
                    .pd-usertype {
                        background-color: #7B7B7B;
                        padding: 20px 0;
                    }
                }

                .pd__usertype__cont {
                    border-bottom: 1px solid #707070;

                    .pd__usertype {
                        margin: 15px 0;
                        background-color: #7B7B7B;
                        display: flex;
                        align-items: center;
                        padding: 20px;

                        .pd__usertype__text {
                            width: 75%;

                            a {
                                color: #FFF;
                                font-size: 18px;
                            }
                        }

                        .pd__usertype__img {
                            width: 25%;
                            margin: 0;
                        }
                    }
                }

                .pd__brand {
                    padding: 5px 0;
                    border-bottom: 1px solid #707070;
                }

                .pd__brand__img {
                    padding: 10px 0;
                    width: 50%;
                }

                .Regdis__identifier {
                    margin: 15px 0;
                    width: 100%;

                    .identifier__block {
                        width: 100%;
                        background: url('../../assects/images/home/skyblue-bg.png') repeat;
                        padding: 5%;
                        display: block;

                        .mt-4 {
                            margin-top: 0 !important;
                        }


                        .dos-heading {
                            font-size: 20px;
                            line-height: 28px;
                            color: #FFF;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }

                        .dos-subheading {
                            font-size: 18px;
                            color: #2b2b2b;
                            font-weight: 600;
                            line-height: 26px;
                            margin-bottom: 20px;
                        }

                        .dos {
                            margin: 0px;
                            overflow: hidden;
                            padding: 0px;

                            li {
                                background-color: #fff;
                                font-weight: 600;
                                list-style-type: none;
                                margin: 0px 0px 30px 0px;
                                padding: 15px 1px 15px 15px;
                                width: 100%;
                                line-height: 2rem;

                                a {
                                    text-decoration: none;
                                    color: #2b2b2b;
                                    display: flex;
                                    align-items: center;
                                    font-size: 18px;
                                    line-height: 26px;

                                    img {
                                        width: 40px;
                                        height: 40px;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.Pd__slider__section {
    margin: 4em 0 0;

    .Pd__slider__title {
        display: flex;
        width: 90%;
        margin: auto;
        overflow: hidden;
        margin-bottom: 2em;
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
        justify-content: center;

        .heading__underlines {
            font-size: 24px;
            padding: 15px;
        }
    }

    .BestSeller__block {
        padding: 0;
        background: transparent;
        box-shadow: none;
    }
}

.casual__gaming__cont {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 7px;

    img {
        width: 100%;
        height: auto;
    }
}

.brands__cont {
    margin: 4em 0;

    .brand__logo__block {
        max-width: 85%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        justify-content: center;
        // padding: 20px;
    }

    .brand__logo {
        display: flex;
        border: 2px solid #707070;
        border-radius: 50%;
        min-width: 180px;
        min-height: 180px;
        max-width: 180px;
        max-height: 180px;
        justify-content: center;
        // align-items: center;
        padding: 10px;

        img {
            width: 100%;
            border-radius: 50%;
            height: 100%;
        }
    }

    .brand__name {
        display: block;
        padding: 15px 0px;
        text-align: center;
        font-weight: 600;
        color: #757575;
        font-size: 20px;
    }

    .brand__tag__block {
        max-width: 70%;
        margin: auto;
        display: flex;
        justify-content: center;
        padding: 20px;
        flex-wrap: wrap;
        column-gap: 10px;

        .brand__tag__col {
            border: 2px solid #707070;
            border-radius: 10px;
            padding: 5px 10px;
            margin-bottom: 15px;

            .brand__tag_name {
                color: #76716F;
                font-size: 18px;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                border: 1px solid #707070;
                padding: 15px;

                .Pd__details__gallery {
                    .Pd__details__sku {
                        font-weight: 500;
                    }

                    .Pd_product__lable {
                        height: 120px;

                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 26%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 26%;
                            }
                        }
                    }
                }

                .Pd__details__des {
                    .Pd__details__brand {
                        font-size: 20px;
                    }

                    .Pd__details__title {
                        font-size: 24px;
                    }

                    .product__tab__cont {
                        background-color: #FFF;
                        margin: 1.5em 0;
                    }
                }

                .Pd__details__action {
                    width: 21%;

                    .pd__price__block {
                        height: 85px;

                        .pd__price__block__test {
                            width: 40%;
                            font-size: 18px;
                        }

                        .pd__price__block__price {
                            width: 60%;
                            font-size: 28px;

                            span.product__discount {
                                font-size: 16px;
                            }
                        }
                    }

                    .pd__usertype__cont {
                        .pd__usertype {
                            .pd__usertype__text {
                                a {
                                    font-size: 16px;
                                }
                            }

                            .pd__usertype__img {
                                img {
                                    width: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .brands__cont {

        .brand__tag__block,
        .brand__logo__block {
            max-width: 85%;
        }
    }
}

@media only screen and (max-width: 1366px) {
    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                .Pd__details__gallery {
                    .Pd_product__lable {
                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 26%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 26%;
                            }
                        }
                    }
                }
            }
        }
    }

    .brands__cont {

        .brand__tag__block,
        .brand__logo__block {
            max-width: 85%;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                flex-wrap: wrap;

                .Pd__details__gallery {
                    width: 49%;

                    .react-slider__imgZoom {
                        display: none;
                    }

                    .Pd_product__lable {
                        height: 135px;

                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 25%;
                                right: -40%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 25%;
                                left: -40%;
                            }
                        }
                    }
                }

                .Pd__details__des {
                    width: 49%;

                    .Pd__details__brand {
                        font-size: 18px;
                    }

                    .Pd__details__title {
                        font-size: 20px;
                    }

                    .product__tab__cont {
                        background-color: #FFF;
                        margin: 1.5em 0;
                    }
                }

                .Pd__details__action {
                    margin-top: 30px;
                    width: 100%;

                    display: flex;
                    justify-content: space-between;

                    .Pd__details__action_tab {
                        display: block;
                        width: 49%;

                        .Regdis__identifier {
                            margin-top: 0px;
                        }
                    }

                    .Pd__details__action_des {
                        width: 49%;

                        .Regdis__identifier {
                            display: none;
                        }
                    }

                    // display: grid;
                    // justify-content: space-between;

                    // .pd__price__block {
                    //     height: 70px;

                    //     .pd__price__block__test {
                    //         width: 36%;
                    //         font-size: 16px;
                    //     } 

                    //     .pd__price__block__price {
                    //         width: 64%;
                    //         font-size: 22px;
                    //         span.product__discount {
                    //             font-size: 16px;
                    //         }
                    //     }
                    // }

                    // .pdbrand {
                    //     grid-row-start: 1;
                    //     grid-row-end: 3;
                    //     grid-column-start: 1;
                    //     grid-column-end: 6;
                    // }

                    // .pdpriceblock {
                    //     grid-row-start: 1;
                    //     grid-row-end: 2;
                    //     grid-column-start: 7;
                    //     grid-column-end: 12;
                    // }

                    // .cartinputblock {
                    //     grid-row-start: 2;
                    //     grid-row-end: 3;
                    //     grid-column-start: 7;
                    //     grid-column-end: 12;
                    // }
                }
            }
        }
    }

    .brands__cont {

        .brand__tag__block,
        .brand__logo__block {
            max-width: 100%;
            padding: 0 15px;
        }

        .brand__logo {
            border: 1px solid #707070;
            min-width: 140px;
            min-height: 140px;
            max-width: 140px;
            max-height: 140px;
        }

        .brand__name {
            font-size: 16px;
        }

        .brand__tag__block {
            padding: 20px;

            .brand__tag__col {
                border: 1px solid #707070;

                .brand__tag_name {
                    color: #76716F;
                    font-size: 16px;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {

                .Pd__details__gallery {
                    width: 49%;


                    .Pd_product__lable {
                        height: 135px;

                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 23%;
                                right: -38%;
                                font-size: 12px;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 23%;
                                left: -38%;
                                font-size: 12px;
                            }
                        }

                    }

                }

                .Pd__details__des {
                    width: 49%;

                    .Pd__details__brand {
                        font-size: 20px;
                    }

                    .Pd__details__title {
                        font-size: 24px;
                    }

                    .product__tab__cont {
                        background-color: #FFF;
                        margin: 1.5em 0;
                    }
                }
            }
        }
    }

    .brands__cont {

        .brand__tag__block,
        .brand__logo__block {
            max-width: 95%;
        }

        .brand__logo {
            margin: auto;
            min-width: 100px;
            min-height: 100px;
            max-width: 100px;
            max-height: 100px;
        }

        .brand__name {
            font-weight: 400;
            font-size: 12px;
        }

        .brand__logo__col {
            text-align: center;
        }
    }
}

@media only screen and (max-width: 767px) {
    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                .Pd__details__gallery {
                    .Pd_product__lable {

                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 20%;
                                right: -35%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 20%;
                                left: -35%;
                            }
                        }

                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                .Pd__details__gallery {
                    width: 100%;
                    margin-bottom: 15px;

                    .Pd_product__lable {

                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 28%;
                                right: -38%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 28%;
                                left: -38%;
                            }
                        }

                    }
                }

                .Pd__details__des {
                    width: 100%;

                    .Pd__details__brand {
                        font-size: 16px;
                    }

                    .Pd__details__title {
                        font-size: 20px;
                    }
                }

                .Pd__details__action {
                    margin-top: 15px;
                    width: 100%;
                    display: block;

                    .Pd__details__action_tab {
                        display: none;
                    }

                    .Pd__details__action_des {
                        width: 100%;

                        .Regdis__identifier {
                            display: block;
                        }
                    }

                    .pdpriceblock {
                        width: 100%;
                    }

                    .cartinputblock {
                        width: 100%;

                        .cart__action {
                            border-bottom: 1px solid #707070;
                        }
                    }

                    .pd__usertype__cont {
                        width: 100%;
                    }

                    .pdbrand {
                        width: 100%;
                    }

                }
            }
        }
    }

    // .brands__cont {
    //     .brand__logo__block {
    //         flex-wrap: wrap;
    //         .brand__logo__col {
    //             width: 50%;
    //         }
    //     }
    // }
}

@media only screen and (max-width: 576px) {
    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                .Pd__details__gallery {
                    .Pd_product__lable {

                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 20%;
                                right: -40%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 20%;
                                left: -40%;
                            }
                        }

                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .Pd__container.Main__section {
        margin-top: 146px !important;
    }

    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                .Pd__details__gallery {
                    .Pd_product__lable {
                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 23%;
                                right: -36%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 23%;
                                left: -36%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .Pd__container.Main__section {
        margin-top: 167px !important;
    }

    .Pd__details__section {
        .Pd__details__container {
            .Pd__details__block {
                .Pd__details__gallery {
                    .Pd_product__lable {

                        .product__lable {
                            .offer__label {
                                width: 100%;
                                top: 24%;
                                right: -33%;
                            }

                            .pre__sale {
                                width: 100%;
                                top: 24%;
                                left: -33%;
                            }
                        }

                    }
                }
            }
        }
    }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    background-color: transparent;
    color: black;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #aaa;
    color: white;
}