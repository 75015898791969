.password_reset {
    min-height: 60vh;
    max-width: 1920px;
    margin: 0 auto;
    .password_reset_container {
      max-width: 1680px;
      width: 100%;
      margin: auto;
      padding: 3em 0;
      
      .password_reset_block {
        padding: 3em 1em;
        border: 1px solid rgba(0, 0, 0, .175);
        border-radius: 0.375rem;
        max-width: 35%;
        margin:auto;
        h1 {
            color: #0da5e8;
            font-size: 28px;
            text-align: center;
        }
        p {
            margin-bottom: 25px;
            text-align: center;
        }
        .b2cregister__validations {
          display: flex;
          color: red;
          justify-content: flex-end;
          margin: 1em 0;
        }
      }
  
      .form-control {
        -webkit-appearance: none;
        appearance: none;
        background-clip: padding-box;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.375rem;
        color: #212529;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 0.375rem 0.75rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        width: 100%;
    }

  
      .password_reset_form {
        display: flex;
        flex-direction: column;
        // margin-top: 3em;
  
        & > input {
          height: 45px;
          padding-left: 10px;
        }
  
        label {
            input {
            margin: 0;
            margin-right: 10px;
          }
        }
        .password_reset_create {
          width: max-content;
          margin: 1em auto;
          background-color: #000;
          color: white;
          padding: 10px 3em;
          border: none;
          outline: none;
        }
        .password_reset_create:hover {
            background-color: #0da5e8;
        }
  
        .password_reset_error {
          color: red;
          text-align: left;
        }
      }
    }
  }


//   Responsive 

@media screen and (min-width:320px) and (max-width:575px) {
    .password_reset_block {
       
      
        max-width: 90% !important;
        margin-top: -1.5rem !important;
    }
    
}

@media screen and (min-width:360px) and (max-width:760px) {
  .password_reset_block {
     
    
      max-width: 90% !important;
      margin-top: -1.5rem !important;
  }
  
}
@media screen and (min-width:761px) and (max-width:1024px) {
  .password_reset_block {
     
    
      max-width: 60% !important;
      margin-top: 1rem !important;
  }
  
}
@media screen and (min-width:1025px) and (max-width:1192px) {
  .password_reset_block {
     
    
      max-width: 70% !important;
      margin-top: 1.5rem !important;
  }
  
}

@media screen and (min-width:1193px) and (max-width:1399px) {
  .password_reset_block {
     
    
      max-width: 40% !important;
      margin-top: 4.5rem !important;
  }
  
}
@media screen and (min-width:1400px) and (max-width:1919px) {
  .password_reset_block {
      max-width: 50% !important;
      margin-top: 4.9rem !important;
  }
  
}
