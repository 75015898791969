.CartListing__warp {
    .CartListing__title {
        h1 {
            font-size: 32px;
            color: #0da5e8;
            font-weight: 500;
            margin-bottom: 35px;
            text-transform: capitalize;
        }
    }

    .CartListing__section {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .CartListing__list {
            width: 75%;

            .CartTable {
                .CartTable__head {
                    display: flex;
                    border: 1px solid #dedede;
                    background-color: #dedede;
                    .CartTable__head__title {
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #FFF;
                        text-transform: capitalize;
                        
                        &:last-child {
                            border: none;
                        }

                        span {
                            font-size: 18px;
                            color: #2b2b2b;
                            font-weight: 500;
                        }
                    }

                    
                }

                .CartTable__row {
                    display: flex;
                    border: 1px solid #dedede;
                    border-top: none;

                    .immediate_service {
                        width: 100%;
                        background-color: #65C24D;
                        padding: 12px;
                        text-align: center;
                        .label{
                            text-transform: uppercase;
                            color: #FFF;
                        }
                    }

                    .product_immediate_service {
                        background-color: #65C24D;
                        border-radius: 22px;
                        padding: 8px 12px;
                        color: #FFF;
                        font-size: 14px;
                        width: 100%;
                        text-align: center;
                        margin-top: 10px;
                    }

                    .pre_service {
                        width: 100%;
                        background-color: red;
                        padding: 12px;
                        text-align: center;
                        .label{
                            text-transform: uppercase;
                            color: #FFF;
                        }
                    }

                    .product_pre_service {
                        background-color: red;
                        border-radius: 22px;
                        padding: 8px 12px;
                        color: #FFF;
                        font-size: 14px;
                        width: 100%;
                        text-align: center;
                        margin-top: 10px;
                    }

                    .CartTable__row_col {
                        padding: 25px 10px;
                        // border-right: 1px solid #dedede;
                        // &:last-child {
                        //     border: none;
                        // }
                    }

                    .CartTable__prod {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .Product__Image {
                            width: 22%;

                            img {
                                width: 100%;
                                height: auto;
                            }
                        }

                        .Product__Details {
                            width: 74%;

                            .Product__Name {
                                display: block;
                                color: #2b2b2b;
                                margin-bottom: 7px;
                            }

                            .Product__Sku {
                                .label {
                                    font-weight: 600;
                                    color: #2b2b2b;
                                }
                            }
                        }
                    }

                    .CartTable__action,
                    .CartTable__discount,
                    .CartTable__subtotal,
                    .CartTable__client,
                    .CartTable__price {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .currency {
                            margin-left: 5px;
                        }
                    }

                    .CartTable__action {
                        .CartTable__action__delete svg{
                            cursor: pointer;
                            color: #ff0000ad;
                            width: 28px;
                            height: 28px;
                            margin: 8px 12px 8px 8px;
                        }

                        .CartTable__action__update{
                            cursor: pointer;
                            margin-top: 4px;
                        }
                    }

                    .CartTable__quan {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .CartQuantity {
                            display: flex;
                            width: 70%;

                            .btn__decrease,
                            .btn__increase {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 26%;
                                min-height: 38px;

                                background-color: #2b2b2b;
                                border: none;
                                outline: none;

                                .inde__icon {
                                    color: #FFFFFF;
                                    width: 20px;
                                    height: 20px;
                                }

                                &:hover {
                                    background-color: #0da5e8;
                                }
                            }

                            .cart__input {
                                width: 48%;
                                height: 38px;
                                text-align: center;
                                border-left: none;
                                border-right: none;
                                border-top: 1px solid #2b2b2b;
                                border-bottom: 1px solid #2b2b2b;
                            }
                        }
                    }
                }

                .CartTable__footer {
                    display: flex;
                    justify-content: right;
                    gap: 25px;
                    padding: 25px 0;

                    .CartTable__footer__action {
                        color: #FFF;
                        background-color: #000;
                        border: none;
                        outline: none;
                        padding: 12px 50px;
                        border-radius: 4px;

                        &:hover {
                            background-color: #0da5e8;
                        }
                    }
                }
                

                .CartTable__prod {
                    width: 30%;
                }

                .CartTable__price {
                    width: 12%;
                }

                .CartTable__discount {
                    width: 9%;
                }

                .CartTable__client {
                    width: 12%;
                }

                .CartTable__quan {
                    width: 15%;
                }

                .CartTable__subtotal {
                    width: 12%;
                }

                .CartTable__action {
                    width: 10%;
                }
            }
        }

        .CartListing__summery {
            width: 23%;
            height: max-content;

            .CartListing__summery__block {
                background-color: #dceff7;
                border: 1px solid #0da5e8;
                padding: 10px;
    
                .CartListing__summery__title {
                    color: #FFF;
                    background-color: #0da5e8;
                    padding: 10px;
                    text-align: center;
                }

                .CartListing__summery__body {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 1px;
                    border-bottom: 1px solid #0da5e8;

                    .label {
                        display: block;
                        width: 70%;
                        text-align: right;
                    }

                    .price {
                        display: block;
                        width: 30%;
                        text-align: right;
                    }

                    .currency {
                        margin-left: 5px;
                    }
                }

                .CartListing__summery__footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 1px 5px;

                    .label {
                        display: block;
                        width: 70%;
                        text-align: right;
                        font-weight: 600;
                    }

                    .price {
                        display: block;
                        width: 30%;
                        text-align: right;
                        font-weight: 600;
                    }

                    .currency {
                        margin-left: 5px;
                    }
                }

            }

            .CartListing__summery__nav {
                margin-top: 25px;

                .CartSummeryNav {
                    color: #FFF;
                    background-color: #000;
                    border: none;
                    outline: none;
                    width: 100%;
                    padding: 12px;
                    font-size: 18px;
                    border-radius: 4px;

                    &:hover {
                        background-color: #0da5e8;
                    }
                }
            }
            
        }
    }
}

.clearCartTable {
    .modal-content {
        .clearAllBody {
            margin: 40px 25px;
        }
    }

    .modal-footer {
        .CartTable__modal__action {
            color: #FFF;
            background-color: #000;
            border: none;
            outline: none;
            padding: 10px 40px;
            border-radius: 4px;

            &:hover {
                background-color: #0da5e8;
            }
        }
    }
}

.CartListing__warp  {
    .CartListing__section {
        .CartListing__list {
            .CartTable {
                .CartTable__head {
                    .CartTable__head__title.CartTable__price_discount {
                        display: none;
                    }
                }

                .CartTable__row {
                    .CartTable__row_col.CartTable__price_discount {  
                        display: none;
                    }

                    .mobile__res {
                        display: none;
                    }
                }
            }
        }
    }
}

.CartListing__customer__details {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 35px;
    gap: 2%;

    .myaccount__label {
        text-transform: uppercase;
    }

    .customer__details_name {
        border: 1px solid #dedede;
        width: 75%;
        padding: 12px;
        font-size: 24px;
        font-weight: 600;
        flex: 1;
    }

    .customer__data__id {
        border: 1px solid #dedede;
        padding: 12px;
        font-size: 18px;
        min-width: 23%;
    }
}

@media only screen and (max-width: 1440px) {
    .CartListing__warp {
        .CartListing__section {
            .CartListing__list {
                .CartTable {
                    .CartTable__row {
                        .CartTable__quan {
                            .CartQuantity {
                                display: flex;
                                width: 90%;
                            }
                        }
                    }
                    
                    .CartTable__discount {
                        width: 12%;
                    }
    
                    .CartTable__quan {
                        width: 13%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .CartListing__warp {
        .CartListing__section {
            flex-wrap: wrap;

            .CartListing__list {
                width: 100%;
            }
    
            .CartListing__summery {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }

    .CartListing__customer__details {
        flex-wrap: wrap;
        row-gap: 20px;

        .customer__details_name {
            width: 100%;
            font-size: 24px;
        }
    
        .customer__data__id {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .CartListing__warp  {
        .CartListing__section {
            .CartListing__list {
                .CartTable {
                    .CartTable__head {
                        .CartTable__head__title.CartTable__price_discount {
                            display: flex;
                            width: 23%;
                        }
                        .CartTable__price {
                            display: none;
                        }

                        .CartTable__discount {
                            display: none;
                        }
                        
                    }

                    .CartTable__row {
                        .CartTable__row_col.CartTable__price_discount {  
                            display: flex;
                            flex-direction: column;
                            gap: 7px;
                            width: 23%;

                            span {
                                display: flex;
                                gap: 5px;
                                label {
                                    font-weight: 600;
                                    width: 90px;
                                }
                            }

                            .CartTable__client_price {
                                display: none;
                            }

                            
                        }

                        .CartTable__price {
                            display: none;
                        }

                        .CartTable__discount {
                            display: none;
                        }
                    }
                }
            }
        }
    }
     
}

@media only screen and (max-width: 899px) {
    .CartListing__warp  {
        .CartListing__section {
            .CartListing__list {
                .CartTable {
                    .CartTable__head { 
                        .CartTable__head__title.CartTable__client {
                            display: none;
                        }

                        .CartTable__head__title.CartTable__price_discount {
                            width: 28%;
                        }
                    }
                    .CartTable__row {
                        .CartTable__row_col.CartTable__price_discount {  
                            display: flex;
                            flex-direction: column;
                            gap: 7px;
                            width: 28%;

                            span {
                                gap: 5px;
                                label {
                                    font-weight: 600;
                                    width: 90px;
                                }
                            }

                            .CartTable__client_price {
                                display: flex;
                            }
                        }

                        .CartTable__client {
                            display: none;
                        }
                    }

                    .CartTable__subtotal,
                    .CartTable__quan {
                        width: 15%;
                    }

                    .CartTable__action {
                        width: 13%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .CartListing__warp  {
        .CartListing__section {
            .CartListing__list {
                .CartTable {
                    .CartTable__head { 
                        .CartTable__head__title.CartTable__client {
                            display: none;
                        }

                        .CartTable__head__title.CartTable__price_discount {
                            width: 28%;
                        }

                        .CartTable__head__title.CartTable__action {
                            width: 10%;
                            span{
                                font-size: 0px;
                            }
                        } 
                    }

                    .CartTable__row {
                        .CartTable__row_col.CartTable__price_discount {  
                            display: flex;
                            flex-direction: column;
                            gap: 7px;
                            width: 28%;

                            span {
                                gap: 5px;
                                label {
                                    font-weight: 600;
                                    width: 90px;
                                }
                            }

                            .CartTable__client_price {
                                display: flex;
                            }
                        }

                        .CartTable__client {
                            display: none;
                        }
                    }

                    .CartTable__subtotal,
                    .CartTable__quan {
                        width: 17%;
                    }

                    .CartTable__action {
                        width: 10%;
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .CartListing__customer__details {

        .customer__details_name {
            font-size: 18px;
        }
    
        .customer__data__id {
            font-size: 18px;

        }
    }
}

@media only screen and (max-width: 650px) {
    .CartListing__warp  {
        .CartListing__section {
            .CartListing__list {
                .CartTable {
                    .CartTable__head { 
                        display: none;
                    }

                    .CartTable__row {
                        flex-wrap: wrap;
                        padding-bottom: 25px;

                        & > :first-child {
                            border-top: 1px solid #dedede;
                        }

                        .CartTable__prod {
                            width: 100%;

                            .Product__Image {
                                width: 35%;
                            }

                            .Product__Details {
                                width: 63%;
                                .mobile__res {
                                    display: block;

                                    .Product__price,
                                    .Product__disc,
                                    .Product__client_price {
                                        display: block;
                                        margin: 5px 0;
                                        .label {
                                            font-weight: 600;
                                            color: #2b2b2b;
                                        }
                                    }
                                }
                            }
                        }

                        .CartTable__row_col.CartTable__price_discount {
                            display: none;
                        }

                        .CartTable__row_col.CartTable__quan {
                            padding: 0px;
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                        }

                        .CartTable__subtotal {
                            width: 30%;
                            padding: 0 5px;
                            font-size: 22px;
                        }

                        .CartTable__action {
                            flex-direction: row-reverse;
                            width: 20%;
                            padding: 0 5px;

                            .CartTable__action__delete svg {
                                width: 20px;
                                height: 20px;
                                margin: 8px;
                            }

                            .CartTable__action__update {
                                img {
                                    width: 22px;
                                    height: 22px;
                                }
                                margin-top: 0px;
                            }
                        }
                    }

                    .CartTable__footer {
                        flex-direction: column;

                        .CartTable__footer__action {
                            width: 100%;
                        }
                    }

                }
            }
        }
    }
}