$defaultColor: #0da5e8;
@mixin box {
    .BillingAddressInfo__list__radio {
        display: flex;
        align-items: baseline;
        cursor: pointer;
        padding: 10px;
        flex-wrap: wrap;
        overflow: auto;
        overflow-x: hidden;
        gap: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .BAI__defaultText {
            color: $defaultColor;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 10px;
            height: 30px;

            span {
                color: black;
            }
        }
        p {
            margin-bottom: 8px;
        }

        & > label {
            width: 100%;
            height: max-content;
            overflow: auto;
        }

        .ShippingAddressInfo__list__radio__checkbox {
            display: flex;
            gap: 10px;
        }
    }
}
.BillingAddressInfo {
    .bai__title {
        color: $defaultColor;
        font-size: 32px;
        margin-bottom: 36px;
    }
    @include box;
    .BillingAddressInfo__container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;
    
        .BillingAddressInfo__list {
            display: grid;
            width: calc(100% / 3 - 20px);
            position: relative;
            // .BillingAddressInfo__list__radio {
            //     display: flex;
            //     align-items: baseline;
            //     cursor: pointer;
            //     padding: 10px;
            //     flex-wrap: wrap;
            //     overflow: auto;
            //     overflow-x: hidden;
            //     gap: 10px;
            //     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            //     .BAI__defaultText {
            //         color: $defaultColor;
            //         text-decoration: underline;
            //         font-weight: 600;
            //         font-size: 20px;
            //         margin-bottom: 10px;
            //         height: 30px;
            //     }

            //     & > label {
            //         width: 100%;
            //         height: 150px;
            //         overflow: auto;
            //     }

            //     .ShippingAddressInfo__list__radio__checkbox {
            //         display: flex;
            //         gap: 10px;
            //     }
            // }
            @include box;
        
            .BillingAddressInfo__list__editAddress, .BillingAddressInfo__list__editDisabled {
                flex: unset;
                flex-grow: 8;
                background-color: $defaultColor;
                color: white;
                padding: 3px 10px;
                border: none;
                height: 40px;
                cursor: pointer;
            }
            .shippingticked, .billingticked {
                position: absolute;
                right: 0;
                cursor: auto;
            }
            .BillingAddressInfo__list__editDisabled {
                // background-color: #2b2b2b;
                pointer-events: none;
                opacity: .4;
            }
    
            p {
                margin: 0;
            }
        }

        .BAI__currentBilling {
            display: none;
            width: 100% !important;
            .BillingAddressInfo__list__radio {
                label {
                    height: max-content;
                }
            }
        }
    }

    .BillingAddressInfo__newAddress {
        background-color: $defaultColor;
        color: white;
        padding: 3px 10px;
        border: none;
        margin: 1.5em 0;
        cursor: pointer;
    }
}


@media(max-width: 1280px) {
    .BillingAddressInfo {
        .BillingAddressInfo__container {
            width: 100%;
            gap: 0;
            .BillingAddressInfo__list {
                margin: 10px;
            }
        }
    }
}

@media(max-width: 1024px) {
    .BillingAddressInfo{
        .BillingAddressInfo__container {
            .BillingAddressInfo__list {
                width: calc(100% / 2 - 20px);
            }
        }
    }
}

@media(max-width: 768px) {
    .BillingAddressInfo {
        .BillingAddressInfo__container {
            .BillingAddressInfo__list {
                width: 100%;
            }
        }
    }
    
}

@media(max-width: 580px) {
    .BillingAddressInfo {
        .bai__title {
            margin-bottom: 1em;
        }
        .BillingAddressInfo__container {
            margin-bottom: 2em;
        }
        .BillingAddressInfo__list {
            margin: 0px !important;
            margin-bottom: 1em !important;
        }
    }
}