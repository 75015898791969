.CatLicensed__section {
    .CatLicensed__content {
        text-align: center;
        max-width: 1200px;
        margin: auto;

        .CatLicensed__title {
            letter-spacing: 0px;
            color: #0DA5E8;
            text-transform: uppercase;
            font-size: 32px;
            line-height: 40px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .CatLicensed__text {
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
            letter-spacing: 0px;
            color: #2b2b2b;
        }
    }

    .CatLicensed__gallery {
        img {
            width: 100%;
            height: auto
        }

        .Gallery__block {
            position: relative;

            .Gallery__content {
                position: absolute;
                bottom: 20px;
                left: 30px;
                padding: 1rem;

                a {
                    color: #FFF;
                }

                .Gallery__title {
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;
                    background-color: #0da5e8;
                    padding: 5px 15px;
                    // text-align: center;
                }

                .Gallery__desc {
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    padding: 8px 10px;
                    position: relative;
                    max-width: 350px;

                    background-color: #000;

                    text-overflow: ellipsis;
                    cursor: pointer;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-height: 64px;
                }
            }
        }
    }

    .Gallery__desc {
        margin-bottom: 0px;

        p {
            margin-bottom: 0px;
        }
    }

    .Gallery__title p {
        margin-bottom: 0px;
    }
}


@media only screen and (max-width: 1366px) {
    .CatLicensed__section {
        padding-top: 0px !important;

        .CatLicensed__content {
            margin: 2rem auto;

            .CatLicensed__title {
                font-size: 28px;
                line-height: 36px;
            }

            .CatLicensed__text {
                font-size: 16px;
                line-height: 26px;

                p {
                    margin: 0px;
                }

                br {
                    display: none;
                }
            }
        }

        .CatLicensed__gallery {
            .Gallery__block {
                .Gallery__content {
                    .Gallery__title {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .Gallery__desc {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .CatLicensed__section {
        .CatLicensed__content {
            .CatLicensed__title {
                font-size: 24px;
                line-height: 32px;
            }

            .CatLicensed__text {
                font-size: 16px;
                line-height: 26px;
            }
        }

        .CatLicensed__gallery {
            .Gallery__block {
                .Gallery__content {
                    bottom: 0px;
                    left: 0px;
                    padding: 15px;

                    .Gallery__title {
                        font-size: 18px;
                        line-height: 24px;
                    }

                    .Gallery__desc {
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .CatLicensed__section {
        .CatLicensed__content {
            margin: 2rem auto;

            .CatLicensed__title {
                font-size: 22px;
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .CatLicensed__section {
        .CatLicensed__content {
            margin: 2rem auto;

            .CatLicensed__title {
                font-size: 22px;
            }
        }

        .CatLicensed__gallery {
            .Gallery__block {
                .Gallery__content {
                    .Gallery__title {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    .Gallery__desc {
                        -webkit-line-clamp: 1;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}