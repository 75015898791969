.CartSummary {
    height: max-content;

    .CartSummary__block {
        background-color: #dceff7;
        border: 1px solid #0da5e8;
        padding: 10px;

        .CartSummary__title {
            color: #FFF;
            background-color: #0da5e8;
            padding: 10px;
            text-align: center;
        }

        .CartSummary__body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 1px;
            border-bottom: 1px solid #0da5e8;

            .label {
                display: block;
                width: 70%;
                text-align: right;
            }

            .price {
                display: block;
                width: 30%;
                text-align: right;
            }

            .currency {
                margin-left: 5px;
            }
        }

        .CartSummary__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 1px 5px;

            .label {
                display: block;
                width: 70%;
                text-align: right;
                font-weight: 600;
            }

            .price {
                display: block;
                width: 30%;
                text-align: right;
                font-weight: 600;
            }

            .currency {
                margin-left: 5px;
            }
        }

    }

    .CartSummary__product {
        max-height: 450px;
        overflow-y: auto;
        margin: 5px 0;

        .CartSummary__Product__Block {
            display: flex;
            justify-content: space-between;
            padding: 15px 0;
            border-bottom: 1px solid #0000001a;
            align-items: center;

            .CartSummary__Product__Image {
                width: 25%;
                padding: 5px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .CartSummary__Product__Details {
                width: 68%;

                .CartSummary__Product__row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    align-items: center;

                    span.CartSummary__Product__Name {
                        color: #2b2b2b;
                        font-size: 16px;
                        cursor: pointer;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        max-height: 48px;
                    }

                    .CartSummary__Product__Qty {
                        color: #2b2b2b;
                        font-size: 16px;
                    }

                    .CartSummary__Product__Price {
                        color: #2b2b2b;
                        font-size: 16px;
                    }

                    .CartSummary__Product__Action {
                        cursor: pointer;
                        svg {
                            width: 22px;
                            height: 22px;
                            color: #2b2b2b;
                        }
                    }
                }

            }
        }
    }
    
    .CartSummary__next {
        margin-top: 2em;
        button {
            color: #FFF;
            background-color: #000;
            border: none;
            outline: none;
            width: 100%;
            padding: 12px;
            font-size: 18px;
            border-radius: 4px;
        }
    }
}