.cmsPages__Section {
    max-width: 1680px;
    width: 100%;
    padding: 0 15px;
    margin: auto;

    .cmsPages__content {
        padding: 3rem 0;
        width: 100%;
        overflow: hidden;
        word-wrap: break-word;

        .container {
            max-width: 100%;
            padding: 0;
            margin: 0;

            img {
                width: 100%;
            }
        }
    }
}

.faq {
    .cmsPages__content {
        h1 {
            font-size: 28px;
            color: #0da5e8;
            text-transform: uppercase;
            margin-bottom: 0px;
        }
        
        a {
            color: #0da5e8;  
            font-size: 18px;
        }

        h2 {
            font-size: 23px;
            color: #2b2b2b;
            text-transform: uppercase;
        }
        
        p {
            font-size: 14px;
            color: #2b2b2b;
        }

        hr {
            margin-top: 20px;
            margin-bottom: 20px;
            border: 0;
            border-top: 1px solid #8d8d8d;
        }

        blockquote {
            padding: 10px 20px;
            margin: 0 0 20px;
            font-size: 17.5px;
            border-left: 5px solid #eee;

            h2 {
                margin: 0px;
            }
        }

        h3 {
            font-size: 19px;
            color: #2b2b2b;
            text-transform: uppercase;
            margin-left: 20px;
        }

        h3 + p,
        h3 + p + p,
        h3 + p + p + p,
        h3 + p + p + p + p {
            margin-left: 22px;
        }
    }
}

@media only screen and (max-width:1440px) {
    .faq {
        .cmsPages__content {
            h1 {
                font-size: 24px;
            }
            
            h2 {
                font-size: 20px;
            }
            
            p {
                font-size: 14px;
            }
        }
    }
}