.Aboutus__container {
    .Aboutus__banner {
        margin-bottom: 3rem;

        .slick-slider {
            width: 100% !important;

            .slick-prev {
                left: 5%;
            }

            .slick-next {
                right: 5%;
            }
        }

        .banner__block {
            position: relative;

            .banner__img img {
                width: 100%;
                height: auto;
            }

            .banner__content {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 10%;
                max-width: 500px;
                margin: auto 0;
                max-height: max-content;
                text-align: left;

                .banner__title {
                    color: #FFF;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 38px;
                    background-color: #0da5e8;
                    padding: 10px 15px;
                    text-align: center;
                }

                .banner__des {
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 28px;
                    padding: 8px 10px;
                    position: relative;
                    background-color: #000;
                }
            }
        }
    }

    .Aboutus__licenses {
        max-width: 1680px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;

        .licenses_header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3rem;

            .content-left {
                width: 42%;
                font-size: 18px;
                color: #2b2b2b;
            }

            .content-right {
                width: 53%;
                border-bottom: 5px solid #2b2b2b;
                font-size: 34px;
                font-weight: 600;
                color: #2b2b2b;
                line-height: 40px;
            }
        }

        .licenses_gallery {
            margin-bottom: 3rem;

            .slick-slider {
                width: 100% !important;

                .slick-prev {
                    left: -0.3%;
                }

                .slick-next {
                    right: -0.4%;
                }
            }

            .licenses_gallery_slider {
                margin-left: -10px;
                margin-right: -10px;
            }

            .licensesGallery__block {
                margin: auto;
                box-sizing: border-box;
                padding: 10px;

                .licensesGallery__img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .licenses_footer {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3rem;

            .content-left {
                width: 42%;
                font-size: 18px;
                color: #2b2b2b;
            }

            .content-right {
                width: 53%;
                display: flex;
                justify-content: space-between;
                height: fit-content;

                .content-txt {
                    width: 60%;
                    font-size: 22px;
                    font-weight: 600;
                    color: #2b2b2b;
                    line-height: 28px;
                    margin: 0;
                }

                .content-action {
                    width: 37%;

                    .content-action-button {
                        background-color: #0da5e8;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        color: #2b2b2b;
                        font-size: 28px;
                        font-weight: 600;
                        margin: 0;
                        padding: 15px;

                        a {
                            color: #2b2b2b;
                            font-size: 28px;
                            font-weight: 600;
                            margin: 0;
                        }

                        h2 {
                            color: #2b2b2b;
                            font-size: 28px;
                            font-weight: 600;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .Aboutus__contactar {
        padding: 3rem 0;
        background-image: url('../../assects/images/aboutus/contactar.svg');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 13%;
        background-color: #0da5e8;

        .contact__block {
            display: flex;
            justify-content: space-between;
            max-width: 1680px;
            padding: 0 15px;
            width: 100%;
            margin: auto;

            .container_left {
                width: 35%;
                margin-top: 7rem;

                h2 {
                    font-size: 38px;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 20px;
                    color: #2b2b2b;
                }
            }

            .container_right {
                width: 55%;

                .container_right_img {
                    img {
                        width: 100% !important;
                        height: auto;
                    }
                }

                .container_right_content {
                    border-top: 5px solid #2b2b2b;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 12px;
                    padding-top: 12px;

                    .left-para {
                        width: 60%;

                        p {
                            font-size: 22px;
                            font-weight: 700;
                            margin: 0;
                            text-transform: uppercase;
                            line-height: 28px;
                        }
                    }

                    .right-btn {
                        width: 36%;

                        p {
                            background-color: #FFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0;
                            padding: 15px;

                            font-size: 28px;
                            font-weight: 700;
                            text-transform: uppercase;

                            a {
                                color: #2b2b2b;
                            }
                        }
                    }
                }
            }
        }
    }

    .Aboutus__request {
        background-color: #b8b8b8;
        padding: 3rem 0;

        .contact__block {
            display: flex;
            justify-content: space-between;
            max-width: 1680px;
            padding: 0 15px;
            width: 100%;
            margin: auto;

            .container_left {
                width: 35%;
                margin-top: 7rem;

                h2 {
                    font-size: 38px;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 20px;
                    color: #2b2b2b;
                }
            }

            .container_right {
                width: 55%;

                .container_right_img {
                    img {
                        width: 100% !important;
                        height: auto;
                    }
                }


            }

            .container_bottom_content {
                width: 100%;
                border-top: 5px solid #2b2b2b;
                display: flex;
                justify-content: space-between;
                margin-top: 12px;
                padding-top: 12px;

                .left-para {
                    width: 34%;

                    p {
                        font-size: 22px;
                        font-weight: 700;
                        margin: 0;
                        text-transform: uppercase;
                        line-height: 28px;
                    }
                }

                .mit-para {
                    width: 34%;

                    p {
                        font-size: 22px;
                        font-weight: 700;
                        margin: 0;
                        text-transform: uppercase;
                        line-height: 28px;
                    }
                }

                .right-btn {
                    width: 30%;
                    background-color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        font-weight: 700;
                        text-transform: uppercase;
                        margin: 0;
                        font-size: 28px;
                    }

                }
            }
        }
    }

    .Aboutus__register {
        background-color: #FFF;
        padding: 3rem 0;

        .contact__block {
            display: flex;
            justify-content: space-between;
            max-width: 1680px;
            padding: 0 15px;
            width: 100%;
            margin: auto;

            .container_left {
                width: 35%;
                margin-top: 7rem;

                h2 {
                    font-size: 38px;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 20px;
                    color: #2b2b2b;
                }
            }

            .container_right {
                width: 55%;

                .container_right_img {
                    img {
                        width: 100% !important;
                        height: auto;
                    }
                }

                .container_right_content {
                    border-top: 5px solid #2b2b2b;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 12px;
                    padding-top: 12px;

                    .left-para {
                        width: 60%;

                        p {
                            font-size: 22px;
                            font-weight: 700;
                            margin: 0;
                            text-transform: uppercase;
                            line-height: 28px;
                        }
                    }

                    .right-btn {
                        width: 35%;
                        background-color: #0da5e8;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 12px;

                        p {
                            font-weight: 700;
                            text-transform: uppercase;
                            margin: 0;
                            font-size: 28px;
                        }

                    }
                }
            }
        }
    }

    .Aboutus__lifestyle {
        background-color: #eaeaea;
        padding: 5rem 0 4rem;

        .Aboutus__lifestyle__blk {
            max-width: 1680px;
            padding: 0 15px;
            width: 100%;
            margin: auto;

            .Aboutus__lifestyle__title {
                border-top: 5px solid #2b2b2b;
                padding-top: 12px;
                margin-bottom: 35px;
                text-transform: uppercase;
                font-size: 36px;
                font-weight: 600;
            }

            .Aboutus__lifestyle_gallery {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .lifeGallery__block {
                    width: 30%;
                    margin-bottom: 3rem;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

            }

        }

    }

    .Aboutus__trustgallery {
        background-color: #FFF;
        padding: 4rem 0;
        background-image: url('../../assects/images/aboutus/bg.svg');
        background-repeat: no-repeat;
        background-position-x: 110%;
        background-position-y: 115%;
        background-size: 30%;

        .Aboutus__trustgallery__blk {
            max-width: 1680px;
            padding: 0 15px;
            width: 100%;
            margin: auto;

            .Aboutus__trustgallery__title {
                padding-top: 12px;
                text-transform: uppercase;
                font-size: 38px;
                font-weight: 600;
                text-align: center;
                max-width: 600px;
                margin: auto;
                margin-bottom: 35px;
            }

            .Aboutus__trustgallery_gallery {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;

                .trustGallery__block {
                    position: relative;
                    margin-bottom: 35px;
                    width: 22%;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .trustGallery__content {
                        position: absolute;
                        top: 20px;
                        left: 20px;

                        .trustGallery__title {
                            font-size: 18px;
                            color: #FFF;
                            max-width: 175px;
                        }
                    }

                }
            }

            .Aboutus__trustgallery__content {
                padding-top: 25px;
                text-align: center;

                p {
                    max-width: 500px;
                    width: 100%;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 36px;
                    margin: auto;
                }
            }

            .Aboutus__trustgallery__action {
                text-align: center;
                padding-top: 25px;

                p {
                    max-width: 275px;
                    width: 100%;
                    font-size: 26px;
                    font-weight: 600;
                    line-height: 36px;
                    margin: auto;
                    background-color: #0da5e8;
                    padding: 15px;
                    color: #FFF;
                }
            }

        }
    }

    .container {
        max-width: 1680px;
        width: 100%;
        margin: auto;
        padding: 0 15px;
    }

    #karac-banner-sec {
        position: relative;

        .container {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(-50%);

            .col-md-6 {
                width: 29%;
            }
        }
    }
}


@media only screen and (max-width: 1440px) {
    .Aboutus__container {
        .Aboutus__banner {
            .banner__block {
                .banner__content {
                    .banner__title {
                        font-size: 28px;
                        line-height: 36px;
                        padding: 6px 15px;
                    }

                    .banner__des {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
        }

        .Aboutus__licenses {
            .licenses_header {
                .content-left {
                    font-size: 16px;
                }

                .content-right {
                    font-size: 30px;
                    line-height: 38px;
                }
            }

            .licenses_gallery {
                .slick-slider {
                    width: 100% !important;

                    .slick-prev {
                        left: 2%;
                    }

                    .slick-next {
                        right: 2%;
                    }
                }
            }
        }

        .Aboutus__contactar {
            .contact__block {
                max-width: 90%;

                .container_left {
                    width: 40%;
                    margin-top: 5rem;

                    h2 {
                        font-size: 30px;
                    }

                    p {
                        font-size: 18px;
                    }
                }

                .container_right {
                    width: 52%;

                    .container_right_content {
                        border-top: 5px solid #2b2b2b;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 12px;
                        padding-top: 12px;

                        .left-para {
                            width: 60%;

                            p {
                                font-size: 22px;
                                font-weight: 700;
                                margin: 0;
                                text-transform: uppercase;
                                line-height: 28px;
                            }
                        }

                        .right-btn {
                            width: 36%;

                            p {
                                background-color: #FFF;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0;
                                padding: 15px;

                                font-size: 28px;
                                font-weight: 700;
                                text-transform: uppercase;

                                a {
                                    color: #2b2b2b;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1200px) {
    .Aboutus__container {
        .Aboutus__licenses {
            .licenses_header {

                .content-right {
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            .licenses_footer {
                .content-right {
                    .content-txt {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .content-action {
                        .content-action-button {
                            font-size: 24px;

                            a {
                                font-size: 24px;
                            }

                            h2 {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {}

@media only screen and (max-width: 768px) {
    .Aboutus__container {
        .Aboutus__banner {
            .banner__block {
                .banner__content {
                    max-width: 400px;

                    .banner__title {
                        font-size: 24px;
                        line-height: 30px;
                    }

                    .banner__des {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .Aboutus__licenses {
            .licenses_header {
                .content-right {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            .licenses_gallery {
                margin-bottom: 2rem;
            }

            .licenses_footer {
                margin-bottom: 2rem;

                .content-left {
                    width: 35%;
                }

                .content-right {
                    width: 62%;

                    .content-txt {
                        font-size: 18px;
                        line-height: 26px;
                    }

                    .content-action {
                        .content-action-button {
                            font-size: 20px;

                            a {
                                font-size: 20px;
                            }

                            h2 {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

    }
}

@media only screen and (max-width: 600px) {
    .Aboutus__container {
        .Aboutus__banner {
            margin-bottom: 2rem;

            .banner__block {
                .banner__content {
                    max-width: 70%;

                    .banner__title {
                        font-size: 18px;
                        line-height: 26px;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .banner__des {
                        font-size: 14px;
                        line-height: 22px;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        min-height: 56px;
                        max-height: 56px;
                        margin: 0;
                        overflow: hidden;
                    }
                }
            }
        }

        .Aboutus__licenses {
            .licenses_header {
                flex-wrap: wrap;
                flex-direction: column-reverse;
                margin-bottom: 1rem;

                .content-left {
                    width: 100%;
                }

                .content-right {
                    width: 100%;
                    font-size: 22px;
                    line-height: 30px;
                    margin-bottom: 1rem;
                }
            }

            .licenses_footer {
                flex-wrap: wrap;
                flex-direction: column-reverse;

                .content-left {
                    width: 100%;
                }

                .content-right {
                    width: 100%;
                    margin-bottom: 1rem;
                    flex-wrap: wrap;

                    .content-txt {
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        padding-bottom: 15px;
                    }

                    .content-action {
                        width: 100%;

                        .content-action-button {
                            font-size: 18px;

                            a {
                                font-size: 18px;
                            }

                            h2 {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .contact__block {
        flex-direction: column;

        .container_left,
        .container_right {
            width: 100% !important;
        }
    }

    .container_bottom_content {
        flex-direction: column;
        row-gap: 15px;

        .left-para,
        .right-btn,
        .mit-para {
            width: 100% !important;
            font-size: 16px !important;
        }
    }

    .container_right_content {
        flex-direction: column;
        row-gap: 15px;

        .right-btn {
            margin-left: 20px;

            p {
                font-size: 16px !important;
            }
        }
    }

    .Aboutus__container .Aboutus__register .contact__block .container_left h2,
    .Aboutus__container .Aboutus__trustgallery .Aboutus__trustgallery__blk .Aboutus__trustgallery__title {
        font-size: 34px;
    }

    .Aboutus__container .Aboutus__trustgallery .Aboutus__trustgallery__blk .Aboutus__trustgallery_gallery .trustGallery__block .trustGallery__content .trustGallery__title {
        font-size: 14px;
    }
}