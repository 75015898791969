.SearchPage__Section {
    max-width: 1680px;
    margin: 0 auto;
    padding: 4rem 15px 2rem;

    .SearchPage__Skeleton {
        .SearchPage__head {
            display: flex;
            justify-content: space-between;

            .SearchPage__view {
                display: flex;
                gap: 5px;
            }
        }
        
        .SearchPage__body {
            display: flex;
            flex-wrap: wrap;
            row-gap: 30px;
            column-gap: 12px;
            margin-top: 25px;

        }
    }

    .SearchPage__block {
        .SearchPage__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .SearchPage__title {
                width: 45%;
                h1 {
                    font-size: 28px;
                    color: #2b2b2b;
                }
            } 

            .SearchPage__view {
                width: 45%;
                display: flex;
                gap: 15px;
                justify-content: flex-end;
                margin-right: 5px;

                    svg {
                        background-color: grey;
                        color: white;
                        outline: 1px solid gray;
                        outline-offset: 2px;
                        cursor: pointer;
                    }
                

                .selected svg{
                    background-color: #0da5e8;
                    outline: 1px solid #0da5e8;
                }
            }
        }

        .SearchPage__body {
            display: flex;
            flex-wrap: wrap;
            row-gap: 30px;
            column-gap: 12px;
            margin-top: 25px;

            .empty__Search {
                text-align: center;
                width: 100%;
                font-size: 25px;
                color: #0da5e8;
                max-width: 300px;
                margin: 35px auto;

                .icon svg {
                    width: 50px;
                    height: 50px;
                }
            }

            .product__block {
                width: calc((100% - 50px) / 5);
            }
        }
    }
}

@media only screen and (max-width: 1590px) {
    .SearchPage__Section {
        .SearchPage__block {
            .SearchPage__head {
                .SearchPage__title {
                    h1 {
                        font-size: 24px;
                    }
                }
            }
            .SearchPage__body {
                .product__block {
                    width: calc((100% - 36px) / 4);
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .SearchPage__Section {
        .SearchPage__block {
            .SearchPage__head {
                .SearchPage__title {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            .SearchPage__body {
                .product__block {
                    width: calc((100% - 24px) / 3);
                }
            }
        }
    }
}

@media only screen and (max-width: 930px) {
    .SearchPage__Section {
        .SearchPage__block {
            .SearchPage__body {
                .product__block {
                    width: calc((100% - 12px) / 2);
                }
            }
        }
    }
}

@media only screen and (max-width: 580px) {
    .SearchPage__Section {
        .SearchPage__block {
            .SearchPage__body {
                .product__block {
                    width: 320px;
                    margin: auto;
                }
            }
        }
    }
}

