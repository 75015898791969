.Search__section {
    .Search__block {
        display: flex;
        align-items: center;
        gap: 10px;

        .Search__input {
            border-radius: unset;
            width: 190px;
        }

        .Search__btn {
            background-color: #484848;
            border: none;
            outline: none;
            padding: 2px 18px;
            color: #FFF;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 25px;
                height: 25px;
                color: #FFF;
            }
        }
    }
}

.Search__section.mobile__search {
    display: none;
}

.Search__section.mobileview__search {
    display: none;
}

.search__back__section {
    display: none;
}

@media only screen and (max-width: 1440px) {
    .Header__Section .Header__bottom .Header__container .Header__search {
        position: absolute;
        right: 15px;
        background-color: #000;
        padding-left: 10px;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1420px) {
    .Search__section.maxview__search {
        display: none;
    }

    .Search__section.mobile__search {
        display: flex;
        position: relative;
        z-index: 101;
    }

    .mobile__search {
        .mobile__search__icon {
            background-color: #484848;
            border: none;
            outline: none;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: #FFF;
                width: 25px;
                height: 25px;
            }
        }

        .mobile__search__icon.mobile_search_open {
            display: none;
        }

        .mobile__search__box {
            display: none;       
            animation: backreveal 1s forwards;

            .Search__block {
                .Search__input {
                    width: 100%;
                    border-radius: 4px;
                }
            }

            .mobile__search__botton {
                background-color: #484848;
                border: none;
                outline: none;
                padding: 10px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                svg {
                    color: #FFF;
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .mobile__search__box.mobile_search_open {
            display: block;
            animation: reveal 1s;
        }

        @keyframes reveal {
            from {
              clip-path: inset(0 0 0 80%);
            }
            to {
              clip-path: inset(0 0 0 0);
            }
        }

        @keyframes backreveal {
            from {
              clip-path: inset(0 0 0 100%);
            }
            to {
              clip-path: inset(0 0 0 0%);
            }
        }
    }

    .search__back__section.mobile_search_open {
        display: block;
        position: fixed;
        opacity: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        z-index: 98;
        transition: left 1s;
        background-color: gray;
    }
}

@media only screen and (max-width: 767px) {
    .Search__section.maxview__search {
        display: none;
    }

    .Search__section.mobile__search {
        display: flex;
        position: relative;
        z-index: 101;
    }

    .mobile__search {
        .mobile__search__icon {
            background-color: #484848;
            border: none;
            outline: none;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: #FFF;
                width: 25px;
                height: 25px;
            }
        }

        .mobile__search__icon.mobile_search_open {
            display: none;
        }

        .mobile__search__box {
            display: none;       
            animation: backreveal 1s forwards;

            .Search__block {
                .Search__input {
                    width: 100%;
                    border-radius: 4px;
                }
            }

            .mobile__search__botton {
                background-color: #484848;
                border: none;
                outline: none;
                padding: 10px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                svg {
                    color: #FFF;
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .mobile__search__box.mobile_search_open {
            display: block;
            animation: reveal 1s;
        }

        @keyframes reveal {
            from {
              clip-path: inset(0 0 0 80%);
            }
            to {
              clip-path: inset(0 0 0 0);
            }
        }

        @keyframes backreveal {
            from {
              clip-path: inset(0 0 0 100%);
            }
            to {
              clip-path: inset(0 0 0 0%);
            }
        }
    }

    .search__back__section.mobile_search_open {
        display: block;
        position: fixed;
        opacity: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        z-index: 98;
        transition: left 1s;
        background-color: gray;
    }

}


@media only screen and (max-width: 425px) {
    .mobile__search {
        .mobile__search__icon {
            padding: 8px;

            svg {
                color: #FFF;
                width: 20px;
                height: 20px;
            }
        }

        .mobile__search__box {
            .mobile__search__botton {
                padding: 8px;

                svg {
                    color: #FFF;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
